// getOrCreateAssociatedTokenAccount.ts
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { SignerWalletAdapterProps } from '@solana/wallet-adapter-base';
import {
  Connection,
  PublicKey,
  Commitment,
  Transaction,
} from '@solana/web3.js';
import { createAssociatedTokenAccountInstruction } from './createAssociatedTokenAccountInstruction';
import { getAccountInfo } from './getAccountInfo';
import { getAssociatedTokenAddress } from './getAssociatedTokenAddress';

export async function getOrCreateAssociatedTokenAccount(
  connection: Connection,
  payer: PublicKey,
  mint: PublicKey,
  owner: PublicKey,
  signTransaction: SignerWalletAdapterProps['signTransaction'],
  allowOwnerOffCurve = false,
  commitment?: Commitment,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
) {
  // console.log("sss" +owner);
  const associatedToken = await getAssociatedTokenAddress(
    mint,
    owner,
    allowOwnerOffCurve,
    programId,
    associatedTokenProgramId
  );

  // This is the optimal logic, considering TX fee, client-side computation, RPC roundtrips and guaranteed idempotent.
  // Sadly we can't do this atomically.
  let account;
  try {
    account = await getAccountInfo(
      connection,
      associatedToken,
      commitment,
      programId
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // TokenAccountNotFoundError can be possible if the associated address has already received some lamports,
    // becoming a system account. Assuming program derived addressing is safe, this is the only case for the
    // TokenInvalidAccountOwnerError in this code path.
    if (
      error.message === 'TokenAccountNotFoundError' ||
      error.message === 'TokenInvalidAccountOwnerError'
    ) {
      // As this isn't atomic, it's possible others can create associated accounts meanwhile.
      try {
        // alert("Kindly approve the transaction to create a token account for you");
        const transaction = new Transaction().add(
          createAssociatedTokenAccountInstruction(
            payer,
            associatedToken,
            owner,
            mint,
            programId,
            associatedTokenProgramId
          )
        );

        const blockHash = await connection.getLatestBlockhash();
        transaction.feePayer = await payer;
        transaction.recentBlockhash = await blockHash.blockhash;
        const signed = await signTransaction(transaction);

        const signature = await connection.sendRawTransaction(
          signed.serialize()
        );
        // alert(signature);
        let tx = await connection.confirmTransaction(signature, 'finalized');
        // alert(tx);
      } catch (error: unknown) {
        // alert(error);
        if (
          error == 'SyntaxError: An invalid or illegal string was specified'
        ) {
          await timeout(15000);
          account = await getAccountInfo(
            connection,
            associatedToken,
            commitment,
            programId
          );
          // clearInterval(refresh2);
          return account.address;
        } else {
          // alert("after tx "+error);

          throw error;
        }

        //  alert("HDHHDHDD "+error);
        // Ignore all errors; for now there is no API-compatible way to selectively ignore the expected
        // instruction error if the associated account exists already.
      }
      try {
        account = await getAccountInfo(
          connection,
          associatedToken,
          commitment,
          programId
        );
      } catch (error: any) {
        // alert("Get Account Info Error  "+error);
        throw error;
      }
      // Now this should always succeed
    } else {
      // alert("General Error  "+ error);
      throw error;
    }
  }

  if (!account.mint.equals(mint.toBuffer()))
    throw Error('TokenInvalidMintError');
  if (!account.owner.equals(owner.toBuffer()))
    throw new Error('TokenInvalidOwnerError');

  return account.address;
}

async function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
