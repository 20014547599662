<ff-alerts></ff-alerts>
<ngx-loading-bar color="#6a0d86"></ngx-loading-bar>





<div class="container">
  <div class="row mt-5">
    <div class="col-md-6 offset-md-3">
      <ngb-alert [dismissible]="true" type="success" *ngIf="alert">
        {{ alert }} &nbsp;<a [href]="tnxlink" target="_blank">view transaction</a>
      </ngb-alert>

       <!-- <ngb-alert [dismissible]="false" type="info" *ngIf="exchange_message" class="text-center">
        {{ exchange_message }}
      </ngb-alert> -->

      <br>
      <div class="card dropcard mb-3">
        <div class="bg">
          <div class=" px-md-5 px-3 py-5">
            <div class="row">
              <div class="col-6">
                <p>Trade</p>
                <div ngbDropdown class="d-inline-block">
                  <button type="button" class="btn btn-clear mybtn text-white" id="dropdownBasic5" ngbDropdownToggle>
                    <span><img width="30" [src]="img" class="mb-1"></span> {{ title}}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic5" class="dropdown-menu2">
                    <div style="position: relative;">
                      <div class="row pabs">
                        <div class="col-12">
                          <div class="input-group mb-3">
                            <span class="input-group-text">
                              <i class="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input type="text" class="form-control" placeholder="Filter by token name"
                              aria-label="Username" aria-describedby="addon-wrapping">
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="dropcontent mt-2">
                      <div class="row ">
                        <ul class="multi-column-dropdown">
                          <li *ngFor="let item of menuItems">
                            <a style="cursor: pointer;" ngbDropdownItem
                              (click)="selectCoin(item?.coin_symbol, item?.coin_img, item?.token_address,item?.type)">
                              <img width="30" [src]="item?.coin_img" alt="">
                              {{item?.name}}
                            </a>
                          </li>
                        </ul>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-6">
                <p class="light mb-2">Balance : {{ walletservice.tokenABalance }} </p>
                <input type="text" class="form-control form-control2 text-end px-3" placeholder="0"
                  [(ngModel)]="defaultSwapValues.amountIn" (ngModelChange)="estimate1($event)">
                <div class="text-center mt-2">
                  <button class="btn btn-sm btn-min me-2" (click)="setMin()">Min</button><button
                    class="btn btn-sm btn-min" (click)="setMax()">Max</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="btn swapbtn" (click)="swap()">
          <img src="assets/img/switch.png" alt="">
        </span>
        <span class="load" *ngIf="spinOn"><i class="fa-solid fa-spinner fa-spin"></i></span>


        <div class="px-md-5 px-3 py-3">
          <div class="row">
            <div class="col-6">
              <p>For (estimated)</p>

              <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-clear mybtn text-white" id="dropdownBasic4" ngbDropdownToggle>
                  <span><img width="30" [src]="img2" width="30" class="mb-1"></span> {{ coin2 }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic4" class="dropdown-menu2">
                  <div style="position: relative;">
                    <div class="row pabs">
                      <div class="col-12">
                        <div class="input-group mb-3">
                          <span class="input-group-text">
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                          <input type="text" class="form-control" placeholder="Filter by token name"
                            aria-label="Username" aria-describedby="addon-wrapping">
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="dropcontent mt-2">
                    <div class="row ">
                      <br>
                      <div class="col-sm-12 ps-3">
                        <ul class="multi-column-dropdown">
                          <li *ngFor="let item of menuItems2">
                            <a ngbDropdownItem style="cursor: pointer;"
                              (click)="anothercoin(item.coin_symbol, item.coin_img,item.token_address,item?.type)">
                              <img width="30" [src]="item.coin_img" alt="">
                              {{item.name}}
                            </a>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div class="col-6">
              <p class="light mb-2">Balance: {{ walletservice.tokenBBalance }}</p>
              <input type="text" class="form-control form-control2 text-end px-3" disabled placeholder="0"
                [(ngModel)]="defaultSwapValues.amountOut">
              <div class="text-center mt-2">
                <!-- <button class="btn btn-sm btn-min me-2">Min</button><button class="btn btn-sm btn-min">Max</button> -->
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-2">
          <!-- <button (click)="test()">Test</button> -->
          <button class="btn btn-connect"
            [disabled]="defaultSwapValues.amountOut <=0 || defaultSwapValues.amountOut==null || noswap"
            (click)="handleSubmit($event)">Swap</button>
        </div>

      </div>
    </div>
  </div>
</div>
