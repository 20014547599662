<ngx-loading-bar color="#EBC6F7"></ngx-loading-bar>


<div class="container">
  <div class="row mt-5">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-dark table-striped ">
          <thead>
            <tr style="cursor: pointer;">
              <th></th>
              <th (click)="sort('coin.coin_symbol')">Coin &nbsp;
                <span *ngIf="key === 'coin.coin_symbol'">
                  <i class="" [class]="reverse? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
                </span>
              </th>

              <th (click)="sort('price_usd')">USD Price &nbsp;
                <span *ngIf="key === 'price_usd'">
                  <i class="" [class]="reverse? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
                </span>
              </th>

              <th (click)="sort('price_gary')">$GARY Price &nbsp;
                <span *ngIf="key === 'price_gary'">
                  <i class="" [class]="reverse? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
                </span>
              </th>

              <th (click)="sort('gary_locked')">$GARY Backing &nbsp;
                <span *ngIf="key === 'gary_locked'">
                  <i class="" [class]="reverse? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
                </span>
              </th>

              <th>Pool Info</th>
              <th>Token info</th>
              <th>Discord</th>
              <th>Website</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of filtercoin">
              <td> <img [src]="item.coin.coin_img" width="30" alt="" style="border-radius: 50%;"> </td>
              <td>
                <a [routerLink]="['/details/',item.coin.coin_symbol ]"
                  routerLinkActive="router-link-active">{{ item.coin.coin_symbol }}
                </a>
              </td>
              <td> ${{ toFix(item.price_usd) }} </td>
              <td>{{ toFix(item.price_gary) }}</td>
              <td>{{ toFix(item.gary_locked) }}</td>
              <td>
                <a [href]="'https://solscan.io/account/' + item.coin.pool_address"
                  target="_blank">{{truncateString(item.coin.pool_address)}}</a>
              </td>
              <td>
                <a [href]="'https://solscan.io/token/' + item.coin.token_address"
                  target="_blank">{{ truncateString(item.coin.token_address) }}</a>
              </td>
              <td>
                <a *ngIf="item.coin.discord_url != null" [href]="item.coin.discord_url" target="_blank">Join Discord</a>
              </td>
              <td>
                <a *ngIf="item.coin.external_url != null" [href]="item.coin.external_url" target="_blank">Visit
                  Website</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row justify-content-between p-2">
        <div class="col-md-9">
          <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
            (pageChange)="refreshCountries()">
          </ngb-pagination>
        </div>
        <div class="col-md-3 align-items-end">
          <select class="form-select" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
            <option [ngValue]="5">5 items per page</option>
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="25">25 items per page</option>
            <option [ngValue]="50">50 items per page</option>
          </select>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-between p-2">



      </div> -->
    </div>
  </div>
</div>
