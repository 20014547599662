import { AppServiceService } from './../_services/app-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coinlist',
  templateUrl: './coinlist.component.html',
  styleUrls: ['./coinlist.component.scss'],
})
export class CoinlistComponent implements OnInit {
  allcoins: any[];
  page = 1;
  pageSize = 5;
  collectionSize = 5;
  filtercoin: any;
  key = 'gary_locked';
  reverse = true;
  direction = 'desc';
  constructor(private app: AppServiceService) {}

  ngOnInit(): void {
    this.getCoins();
  }

  getCoins() {
    this.app.getcoinMarket(this.key, this.direction).subscribe({
      next: (res: any) => {
        console.log(res);
        this.allcoins = res.data;
        this.collectionSize = this.allcoins.length;
        // this.filtercoin = this.allcoins;
        this.refreshCountries();
      },
    });
  }

  refreshCountries() {
    this.filtercoin = this.allcoins
      .map((coin, i) => ({
        id: i + 1,
        ...coin,
      }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }

  toFix(data: number) {
    return data.toFixed(4);
  }

  truncateString(str) {
    if (str.length <= 10) {
      return str;
    }
    return str.slice(0, 10) + '...';
  }

  sort(key: string) {
    this.key = key.toLowerCase();
    this.reverse = !this.reverse;
    if (this.reverse === true) {
      // this.alert.warning('true');
      this.direction = 'desc';
    } else {
      this.direction = 'asc';
      // this.alert.warning('false');
    }
    this.getCoins();
  }

  // sort1(a, b) {
  //   var keyA = new Date(a.updated_at),
  //     keyB = new Date(b.updated_at);
  //   // Compare the 2 dates
  //   if (keyA < keyB) return -1;
  //   if (keyA > keyB) return 1;
  //   return 0;
  // }

  // sortByKey(key) {
  //   console.log(key);
  //   this.reverse = !this.reverse;
  //   let rrr = this.reverse;
  //   // console.log(rrr);
  //   this.allcoins = this.allcoins.sort(function (a, b) {
  //     var x = a[key];
  //     var y = b[key];
  //     return -1;
  //     // if (rrr == false) {

  //     // } else {
  //     //   return 1;
  //     // }
  //     // return x < y ? -1 : x > y ? 1 : 0;
  //   });
  //   // this.sort(key);
  //   console.log(this.allcoins);
  //   this.key = key;
  //   // this.reverse = !this.reverse;
  //   // console.log('sorted');
  //   this.refreshCountries();
  // }
}

// https://solscan.io/token/
// https://solscan.io/account/
