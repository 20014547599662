import { JupiterComponent } from './jupiter/jupiter.component';
import { CreatorSwapComponent } from './creator-swap/creator-swap.component';
import { GaryDetailsComponent } from './gary-details/gary-details.component';
import { SwapDetailsComponent } from './swap-details/swap-details.component';
import { SolswapComponent } from './sol/solswap/solswap.component';
import { CoinlistComponent } from './coinlist/coinlist.component';
import { SwapComponent } from './swap/swap.component';
import { WormholeComponent } from './wormhole/wormhole.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivateSwapComponent } from './private-swap/private-swap.component';
import { GaryswapComponent } from './gary/solswap/garyswap.component';

const routes: Routes = [
  { path: '', redirectTo: 'swap', pathMatch: 'full' },
  { path: 'wormhole', component: WormholeComponent },
  { path: 'sol', component: SolswapComponent },
  { path: 'gary', component: GaryswapComponent },
  // { path: 'swap', component: SwapComponent },
  { path: 'swap', component: JupiterComponent },
  { path: 'jupiter', component: JupiterComponent },
  { path: 'private_swap', component: PrivateSwapComponent },
  { path: 'creator_swap', component: CreatorSwapComponent },
  { path: 'coin_lists', component: CoinlistComponent },
  { path: 'swap/:id', component: SwapComponent },
  { path: 'details', component: GaryDetailsComponent },
  { path: 'details/:id', component: SwapDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
