import { WalletService } from '../../_services/wallet.service';
import { TOKEN_PROGRAM_ID } from '../../../utils/index';
import { Token } from '@solana/spl-token';
import { AppServiceService } from '../../_services/app-service.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { getOrca,OrcaPoolConfig, Percentage,Network } from '@orca-so/sdk';
import Decimal from 'decimal.js';
import * as Tokens from '@orca-so/sdk/dist/constants/tokens';
import { OrcaPoolImpl } from '@orca-so/sdk/dist/model/orca/pool/orca-pool'
import {
  PublicKey,
  SystemProgram,
  Transaction,
  Keypair,
  Connection,
  clusterApiUrl,
  LAMPORTS_PER_SOL,
} from '@solana/web3.js';

import {
  executeSwap,
  estimateSwap,
  tokenSwapProgram,
  getTokenSwapInfo,
  getMintInfo,
  getTokenAccountInfo,
  getOrCreateAssociatedAccount,
  canonicalSwapProgram,
  swapWrappedForCanonical,
  swapCanonicalForWrapped,
} from 'rly-js';

import {
  getAssociatedTokenAddress,
  baseToDec,
  decToBase,
} from '../../../utils';
import { getOrCreateAssociatedTokenAccount } from '../../../utils/getOrCreateAssociatedTokenAccount';
import BN from 'bn.js';
import { EXPLORER_ROOT, NETWORK } from '../../../config';
// import { Provider } from 'rly-js/node_modules/@project-serum/anchor';
import { web3, Provider } from '@project-serum/anchor';
import { AlertService } from '@full-fledged/alerts';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { environment } from 'src/environments/environment';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { getAccountInfo } from 'src/utils/getAccountInfo';
import { CurveType, OrcaPoolParams } from '@orca-so/sdk/dist/model/orca/pool/pool-types';
// import { environment } from '../../environments';

declare interface coinInfo {
  img: string;
  name: string;
  token_address: string;
}
type defaultSwapValues = {
  tokenSwapInfo: string;
  tokenA: string;
  tokenB: string;
  amountIn: number;
  amountOut: number;
  typeA: string;
  typeB: string;
};
type swapResponse = {
  tx: string | null;
};

const cswap = {
  canonicalMintV3: 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh',
  canonicalDataV3: '97exq8nMj13ydaQAwxMN26nfHXtvoVioexYkoB1ZUs2v',
  canonicalMintV2: 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq',
  canonicalDataV2: '61SVmuBgYEChu3vBR4PXtTzHbXYsn78CM7uZCSquieHu',
  wormholeMint: '6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4',
  wormholeData: 'FvdfyPydxRgCnFPwBdrx7B1fuBAZFxWCahATumYvzEdv',
  wormholeData1: 'BuvUZWrTnrBkacCikXsoGW1zA1yMt7D1okq3ZDJrDft8',
};

const tdata = {
  sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh:
    '97exq8nMj13ydaQAwxMN26nfHXtvoVioexYkoB1ZUs2v',
  RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq:
    '61SVmuBgYEChu3vBR4PXtTzHbXYsn78CM7uZCSquieHu',
  '6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4':
    'FvdfyPydxRgCnFPwBdrx7B1fuBAZFxWCahATumYvzEdv',
  wormholev1: 'BuvUZWrTnrBkacCikXsoGW1zA1yMt7D1okq3ZDJrDft8',
};
// const canonicalMint = new PublicKey(
//   'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq'
// );

// const canonicalData = new PublicKey(
//   '4wkz5UF7ziY3Kuz1vxkZBakcZrRoTfup4XPASdhDfnpk'
// );

// const wormholeMint = new PublicKey(
//   '6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4'
// );

// const wormholeData = new PublicKey(
//   'BuvUZWrTnrBkacCikXsoGW1zA1yMt7D1okq3ZDJrDft8'
// );

const ten = new BN(10);
// const defaultSwapValues = {
//   tokenSwapInfo: '',
//   tokenA: '',
//   tokenB: '',
//   amountIn: 0,
//   amountOut: 0,
// } as defaultSwapValues;

const defaultSwapResponse = {} as swapResponse;

// export const coin: coinInfo[] = [
//   {
//     img: 'assets/img/dollar.png',
//     name: '$SRLY',
//     token_address: '3ZLUkydTzCsrKV3DShdHFr8xZALnHPCqfNCjMDtWS6uC',
//   },
//   {
//     img: 'assets/img/solana.png',
//     name: '$GARY',
//     token_address: 'CWv3gBHKZ3EaCfFzEbAwp7ep7j6PAgAm11hx2Su3XYFt',
//   },
// ];
@Component({
  selector: 'app-solswap',
  templateUrl: './solswap.component.html',
  styleUrls: ['./solswap.component.scss'],
})
export class SolswapComponent implements OnInit {
  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly connected$ = this.walletservice.connected$;
  readonly publicKey$ = this.walletservice.publicKey$;
  lamports = 0;
  swapResponseValues: swapResponse;
  recipient = '';

  title = 'Select Coin';
  img = '';
  coin2 = 'Select Coin';
  img2 = '';
  public isCollapsed = true;
  public isCollapsed2 = true;
  public menuItems: any[] = [];
  public menuItems2: any[] = [];
  public estimateOut = 0;
  public tokenABalance = this.walletservice.tokenABalance;
  public tokenBBalance = this.walletservice.tokenBBalance;
  setEstimateOut = 0;
  balance = 0;
  disablebtn = true;
  public defaultSwapValues: defaultSwapValues = {
    tokenSwapInfo: '',
    tokenA: '',
    tokenB: '',
    amountIn: null,
    amountOut: null,
    typeA: null,
    typeB: null,
  };
  public swapDetails = {
    swapId: '',
    tokenA: '',
    tokenB: '',
  };
  // connection = new Connection(environment.solana_api, {
  //   commitment: 'confirmed',
  //   wsEndpoint: environment.solana_api_ws,
  //   confirmTransactionInitialTimeout: 120000,
  // });
  // connection = this.walletservice.connection;
  // wallet = this._hdWalletStore.aanchorWallet$ as Wallet;

  // console.log(this.wallet);
  provider: any;
  wallet: any;
  alert: string = null;
  tnxlink: string;
  refresh: NodeJS.Timeout;
  menuItems4: any;
  wormhole_fee: any;
  wormhole_address: any;
  spinOn = false;
  noswap = false;
  neededTokens: any[];
  serror: boolean;
  canSwap: boolean;
  canswapMessage: any;
  refresh3: NodeJS.Timeout;
  exchange_message: any;
  orcaSolPool: OrcaPoolImpl;
  //  = new AnchorProvider(this.connection, this.wallet, {});
  // provider: Provider = new Provider(this.connection, this.wallet, {});
  constructor(
    private app: AppServiceService,
    private alertService: AlertService,
    private loadingBar: LoadingBarService,
    public walletservice: WalletService,
    private modalService: NgbModal,
    config: NgbModalConfig
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.app.getAllCoins().subscribe({
      next: (res: any) => {
        // console.log(res);
        this.menuItems4 = [
          // {
          //   img: 'assets/img/dollar.png',
          //   name: '$WormHoleRLYv1',
          //   coin_symbol: '$WormHoleRLYv1',
          //   token_address: '6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4',
          //   type: "v1"
          // },
          {
            coin_img: '../../../assets/img/solana.png',
            name: 'Solana',
            coin_symbol: '$SOL',
            token_address: 'solana',
            type: 'v2',
          },
          {
            coin_img: '../../../assets/img/dollar.png',
            name: 'sRLY',
            coin_symbol: '$sRLY',
            token_address: 'srly',
            type: 'v2',
          },
          // ,
          // {
          //   img: 'assets/img/solana.png',
          //   name: 'sRLYv3',
          //   coin_symbol: '$sRLYv3',
          //   token_address: 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh',
          //   type: "v3"
          // }
        ];

        this.menuItems = this.menuItems4;
        this.menuItems2 = this.menuItems4;
        // console.log(this.menuItems);
      },
    });

    // this.provider = new Provider(
    //   this.walletservice.connection,
    //   this.walletservice.wallet,
    //   {}
    // );
    // const a = this._hdWalletStore.anchorWallet$.subscribe();
    // console.log(a);
    // 'Wallet': signTransaction, signAllTransactions, publicKey
  }

  estimate1(event) {
    // this.spinOn = true
    this.defaultSwapValues.amountIn = event;
    if (
      this.walletservice.wallet.publicKey &&
      this.defaultSwapValues.amountIn > 0
    ) {
      this.estimate();
    }
  }
  async selectCoin(name: any, img: any, token_address, type) {
    this.isCollapsed = true;
    this.title = name;
    this.img = img;
    this.defaultSwapValues.tokenA = token_address;
    this.defaultSwapValues.typeA = type;
    this.menuItems2 = [];
    for (let i = 0; i < this.menuItems4.length; i++) {
      const element = this.menuItems4[i];
      if (element.coin_symbol != name) {
        this.menuItems2.push(element);
      }
    }
    // for (let i = 0; i < this.menuItems4.length; i++) {
    //   const element = this.menuItems4[i];
    //   if (element.coin_symbol !== name) {
    //     // console.log(element);
    //     this.menuItems2.push(element);
    //   }
    // }
    let tokenB;
    const orca = getOrca(this.walletservice.connection);
    // const orcaSolPool = orca.getPool(OrcaPoolConfig.sRLY_SOL);
    await this.getPool();
    if (this.defaultSwapValues.tokenA == 'srly') {
      tokenB = this.orcaSolPool.getTokenA();
    } else {
      tokenB = this.orcaSolPool.getTokenB();
    }
    console.log(tokenB);
    const callerTokenAAccount = await getAssociatedTokenAddress(
      tokenB.mint,
      this.walletservice.wallet.publicKey
    );
    // alert
    // alert (1);
    // console.log('hey ' + callerTokenAAccount);
    try {
      if (this.defaultSwapValues.tokenA == 'srly') {
        this.walletservice.tokenABalance = (
          await this.walletservice.connection.getTokenAccountBalance(
            callerTokenAAccount
          )
        ).value.uiAmount;
      } else {
        this.walletservice.tokenABalance =
          await this.walletservice.connection.getBalance(
            this.walletservice.wallet.publicKey
          );
        this.walletservice.tokenABalance =
          this.walletservice.tokenABalance / 1000000000;
      }
      // alert(this.walletservice.tokenABalance)
      this.walletservice.tokenABalance = this.walletservice.tokenABalance;
    } catch (error) {
      this.walletservice.tokenABalance = 0;
    }

    // this.defaultSwapValues.tokenA = name;
    // const data = {
    //   key: this.publicKey$,
    //   address: token_address,
    // };
    // this.app.getBalance(data).subscribe({
    //   next: (res: any) => {
    //     console.log(res);
    //     this.balance = res.data;
    //   },
    // });
    // this.publicKey$, token_address
  }

  async getPool(){
    const orca = getOrca(this.walletservice.connection);
    const srlySolPool:OrcaPoolParams = Object.freeze({
      address: new PublicKey("FyGeyg6HEjBwTEGXF9o78XgKyMkmxSncLe2VECu3FPgo"),
      nonce: 255,
      authority: new PublicKey("BDmEnikYwue2wtzxZFhr29avpQdX3rrQrvSjYJimeteH"),
      poolTokenMint: new PublicKey("HfRgvhgscGX5GaP3rUrZAhh7gS4aJ2UQ7rNVX976rG6P"),
      poolTokenDecimals: 6,
      feeAccount: new PublicKey("EcUL9q6rMrVsyqDR1hMMMyHM6Sqos93SKXBCEd3HgAa7"),
      tokenIds: ["sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh", Tokens.solToken.mint.toString()],
      tokens: {
        ["sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh"]: {

            // ...で展開されるJSONフィールドを直接入力
            // https://github.com/orca-so/typescript-sdk/blob/main/src/constants/tokens.ts
            tag: "sRLY",
            name: "sRLY (Rally Solana) (sRLY)",
            mint: new PublicKey("sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh"),
            scale: 9,
            addr: new PublicKey("7vY5bs27YTWus7KiemXsfaY4E2EzbrV5gXvbx1RaWJ8B"),
        },
        [Tokens.solToken.mint.toString()]: {
          ...Tokens.solToken,
          addr: new PublicKey("JiKtEtLL96sUDQvM1oYXuNaWdHxjdcSgb9M3yqNYs8h"),
        },
      },
      curveType: CurveType.ConstantProduct,
      feeStructure: {
        traderFee: Percentage.fromFraction(25, 10000),
        ownerFee: Percentage.fromFraction(5, 10000),
      },
    });
    // const orcaSolPool = orca.getPool(OrcaPoolConfig.sRLY_SOL);
    this.orcaSolPool = new OrcaPoolImpl(this.walletservice.connection,Network.MAINNET, srlySolPool);
  }
  async estimate() {
    this.spinOn = true;
    await this.getPool();
    let tokenB;
    // alert (this.defaultSwapValues.tokenA);s
    if (this.defaultSwapValues.tokenA == 'srly') {
      tokenB = this.orcaSolPool.getTokenA();
    } else {
      tokenB = this.orcaSolPool.getTokenB();
    }

    const solAmount = new Decimal(this.defaultSwapValues.amountIn);
    const quote = await this.orcaSolPool.getQuote(tokenB, solAmount);
    const orcaAmount = quote.getMinOutputAmount();
    // orcaPool.
    // orca.getPool()
    console.log(orcaAmount);
    this.defaultSwapValues.amountOut = orcaAmount.toNumber();
    this.spinOn = false;
  }
  // if (wallet.publicKey && formValues.amountIn > 0) {
  //     estimate()
  // }

  async anothercoin(name2: any, img2: any, token_address, type) {
    this.isCollapsed2 = true;
    this.coin2 = name2;
    this.img2 = img2;
    this.defaultSwapValues.tokenB = token_address;
    this.defaultSwapValues.typeB = type;
    this.menuItems = [];
    for (let i = 0; i < this.menuItems4.length; i++) {
      const element = this.menuItems4[i];
      if (element.coin_symbol != name2) {
        this.menuItems.push(element);
      }
    }
    // for (let i = 0; i < this.menuItems4.length; i++) {
    //   const element = this.menuItems4[i];
    //   if (element.coin_symbol !== name2) {
    //     // console.log(element);
    //     this.menuItems.push(element);
    //   }
    // }
    // this.app
    //   .getSwap(this.defaultSwapValues.tokenA, this.defaultSwapValues.tokenB)
    //   .subscribe({
    //     next: (res: any) => {
    //       // console.log(res);
    //       if (res.data !== null) {
    //         this.swapDetails.swapId = res.data.swap_id;
    //         this.swapDetails.tokenA = res.data.tokena_address;
    //         this.swapDetails.tokenB = res.data.tokenb_address;
    //       }
    //     },
    //   });
    // console.log('coin2', this.coin2);
    let tokenB;
    const orca = getOrca(this.walletservice.connection);
    // const orcaSolPool = orca.getPool(OrcaPoolConfig.sRLY_SOL);
    await this.getPool();
    if (this.defaultSwapValues.tokenB == 'srly') {
      tokenB = this.orcaSolPool.getTokenA();
      console.log(tokenB);
    } else {
      tokenB = this.orcaSolPool.getTokenB();
      console.log(tokenB);
    }
    const callerTokenBAccount = await getAssociatedTokenAddress(
      tokenB.mint,
      this.walletservice.wallet.publicKey
    );

    try {
      if (this.defaultSwapValues.tokenB == 'srly') {
        this.walletservice.tokenBBalance = (
          await this.walletservice.connection.getTokenAccountBalance(
            callerTokenBAccount
          )
        ).value.uiAmount;
      } else {
        this.walletservice.tokenBBalance =
          await this.walletservice.connection.getBalance(
            this.walletservice.wallet.publicKey
          );
        this.walletservice.tokenBBalance =
          this.walletservice.tokenBBalance / 1000000000;
      }
      // this.walletservice.tokenBBalance =
      //   this.walletservice.tokenBBalance.toString()
      // ;
    } catch (error) {
      this.walletservice.tokenBBalance = 0;
    }

    // this.defaultSwapValues.tokenB = name2;
  }

  async swap() {
    const a = this.title;
    const b = this.coin2;
    const c = this.img;
    const d = this.img2;

    const title1 = this.title;
    const img1 = this.img;
    const token_address1 = this.defaultSwapValues.tokenA;

    const title2 = this.coin2;
    const img2 = this.img2;
    const token_address2 = this.defaultSwapValues.tokenB;

    await this.selectCoin(
      title2,
      img2,
      token_address2,
      this.defaultSwapValues.typeB
    );
    await this.anothercoin(
      title1,
      img1,
      token_address1,
      this.defaultSwapValues.typeA
    );
    this.defaultSwapValues.amountIn = 0;
    this.defaultSwapValues.amountOut = 0;
    // this.title = b;
    // this.coin2 = a;
    // this.img = d;
    // this.img2 = c;
  }
  async ngOnInit() {
    this.app.getWormholeData().subscribe({
      next: (res: any) => {
        this.wormhole_fee = res.data.wormhole_fee.config_value;
        this.wormhole_address = res.data.wormhole_address.config_value;
      },
    });

    this.walletservice.resetBalance();
    // this._hdConnectionStore.setEndpoint('https://solana-api.projectserum.com');
  }

  async handleSubmit(e: any) {
    this.startLoading();
    this.noswap = true;
    this.spinOn = true;
    e.preventDefault();
    if (!this.walletservice.wallet.publicKey) {
      this.alertService.danger('wallet not active');
      // this.stopLoading();
      this.noswap = false;
      this.spinOn = false;
    } else {
      let tokenB, tokenA, callerTokenBAccount, callerTokenAAccount, swapFrom;
      const orca = getOrca(this.walletservice.connection);
      // const orcaSolPool = orca.getPool(OrcaPoolConfig.sRLY_SOL);
      await this.getPool();
      let associatedTokenA, associatedTokenB;
      if (this.defaultSwapValues.tokenB == 'srly') {
        tokenB = this.orcaSolPool.getTokenA();
        swapFrom = this.orcaSolPool.getTokenB();
        tokenA = this.orcaSolPool.getTokenB();
        // alert (tokenB.mint);
        associatedTokenB = await getAssociatedTokenAddress(
          tokenB.mint,
          this.walletservice.wallet.publicKey
        );

        callerTokenAAccount = this.walletservice.wallet.publicKey;
        // alert(callerTokenAAccount)
        //  console.log( callerTokenAAccount);
      } else {
        tokenB = this.orcaSolPool.getTokenB();
        tokenA = this.orcaSolPool.getTokenA();
        swapFrom = this.orcaSolPool.getTokenA();
        associatedTokenA = await getAssociatedTokenAddress(
          this.orcaSolPool.getTokenA().mint,
          this.walletservice.wallet.publicKey
        );
        callerTokenBAccount = this.walletservice.wallet.publicKey;
        // console.log(tokenB);
      }

      try {
        this.neededTokens = [];
        if (this.defaultSwapValues.tokenB == 'srly') {
          try {
            callerTokenBAccount = await getAccountInfo(
              this.walletservice.connection,
              associatedTokenB,
              'confirmed',
            );
            // alert(callerTokenBAccount)
            callerTokenBAccount = callerTokenBAccount.address;
          } catch (error) {
            // alert(error);
            if (
              error.message === 'TokenAccountNotFoundError' ||
              error.message === 'TokenInvalidAccountOwnerError'
            ) {
              this.neededTokens.push({
                title: this.coin2,
                token_address: tokenB.mint,
              });
              // console.log(this.neededTokens);
              this.serror = true;
            }
          }
        } else {
          try {
            callerTokenAAccount = await getAccountInfo(
              this.walletservice.connection,
              associatedTokenA,
              'confirmed',
              TOKEN_PROGRAM_ID
            );
            callerTokenAAccount = callerTokenAAccount.address;
            // alert(callerTokenAAccount)
          } catch (error) {
            if (
              error.message === 'TokenAccountNotFoundError' ||
              error.message === 'TokenInvalidAccountOwnerError'
            ) {

              this.neededTokens.push({
                title: this.title,
                token_address: this.orcaSolPool.getTokenA().mint,
              });
              this.serror = true;
            }
          }
        }

        if (this.serror == true) {
          throw 'error';
        }
        try {
          // console.log(swapFrom);
          const solAmount = new Decimal(this.defaultSwapValues.amountIn);
          const quote = await this.orcaSolPool.getQuote(swapFrom, solAmount);
          const orcaAmount = quote.getMinOutputAmount();

          // let sf = swapFrom.mint;
          // console.log(swapFrom);
          const swapPayload = await this.orcaSolPool.swap(
            this.walletservice.wallet.publicKey,
            swapFrom,
            solAmount,
            orcaAmount
          );
          // alert(orcaAmount);

          // const opts = { signers: swapPayload.signers };
          // console.log(swapPayload.transaction);
          // console.log(opts);
          // const signature = await this.walletservice.connection.sendTransaction(swapPayload.transaction, this.walletservice.connection,  swapPayload.signers);
          // const blockHash = await this.walletservice.connection.getLatestBlockhash();
          // // swapPayload.signers = this.walletservice.wallet.publicKey;
          // swapPayload.transaction.feePayer = this.walletservice.wallet.publicKey;
          // swapPayload.transaction.recentBlockhash = await blockHash.blockhash;
          // let tx = await this.walletservice.connection.sendTransaction(swapPayload.transaction, [swapPayload.signers])
          // swapPayload.transaction.partialSign( { signers: swapPayload.signers });
          console.log(swapPayload.signers);
          const signedTransaction = await this.walletservice.hdwallet
            .sendTransaction(
              swapPayload.transaction,
              this.walletservice.connection,
              { signers: swapPayload.signers }
            )
            .subscribe({
              next: async (signature) => {
                {
                  // alert(1)
                  let swapTxId =
                    await this.walletservice.connection.confirmTransaction(
                      signature,
                      'processed'
                    );
                  console.log(swapTxId);
                  // const swapTxId = await swapPayload.execute();

                  this.refresh = setInterval(async () => {
                    let a, b;
                    if (this.defaultSwapValues.tokenA == 'srly') {
                      a = (
                        await this.walletservice.connection.getTokenAccountBalance(
                          callerTokenAAccount
                        )
                      ).value.uiAmount;
                      b =
                        (await this.walletservice.connection.getBalance(
                          this.walletservice.wallet.publicKey
                        )) / 1000000000;
                    } else {
                      a =
                        (await this.walletservice.connection.getBalance(
                          this.walletservice.wallet.publicKey
                        )) / 1000000000;
                      b = (
                        await this.walletservice.connection.getTokenAccountBalance(
                          callerTokenBAccount
                        )
                      ).value.uiAmount;
                    }
                    if (a !== this.walletservice.tokenABalance) {
                      this.walletservice.tokenABalance = a;
                      this.walletservice.tokenBBalance = b;
                      clearInterval(this.refresh);
                    }
                  }, 5000);

                  this.swapResponseValues = { tx: signature };
                  this.tnxlink = `${EXPLORER_ROOT}/tx/${this.swapResponseValues.tx}`;
                  this.alert = `swap successfully executed! `;
                  const html = `
      <h4>${this.alert}</h4>
     <a href=${this.tnxlink} target="_blank">view transaction</a>`;
      this.alertService.success({ html });

                  this.defaultSwapValues.amountIn = 0;
                  this.defaultSwapValues.amountOut = 0;
                  // console.log(callerTokenAAccount);
                  this.stopLoading();
                  this.spinOn = false;
                  this.noswap = false;
                  console.log(signature);
                }
              },
              error: (err) => {
                this.stopLoading();
                this.noswap = false;
                this.spinOn = false;
                this.alertService.danger(err);
                throw err;
              },
            });
          // .subscribe(async (signature) => );
          // await connection.confirmTransaction(signedTransaction, 'processed');
          // const signed = await this.walletservice.wallet.signTransaction(
          //   swapPayload.transaction
          // );
          // console.log(signed);
          // const signature = await this.walletservice.connection.sendRawTransaction(
          //   signed.serialize()
          // );
          // console.log(signedTransaction);

          // const swap_payload = await pool.swap(publicKey, token, input_amount, output_amount);
          // const opts = { signers: swap_payload.signers };
          // const signature = await sendTransaction(swap_payload.transaction, connection, opts);
          // await connection.confirmTransaction(signature, 'processed');
        } catch (error) {
          console.log(error)
          this.stopLoading();
          this.noswap = false;
          this.spinOn = false;
          this.alertService.danger(error);
        }
      } catch (error) {
        console.log(error)
        if(this.neededTokens.length > 0){
          const modalRef = this.modalService.open(NgbdModalContent2);
          // this.modalService.open(NgbdModalContent).result;
          // this.stopLoading();
          modalRef.componentInstance.neededTokens = this.neededTokens;
          modalRef.componentInstance.walletService = this.walletservice;
          modalRef.componentInstance.lo = this.loadingBar;
          this.noswap = false;
          this.spinOn = false;
        }else{
          this.stopLoading();
          this.noswap = false;
          this.spinOn = false;
          this.alertService.danger(error);
        }

        status = 'err';
      }
    }
  }
  // async test() {
  //   // const a = new Token(this.connection,new PublicKey(this.defaultSwapValues.tokenA),TOKEN_PROGRAM_ID,this.wallet);

  //   const callerTokenAAccount = await getOrCreateAssociatedTokenAccount(
  //     this.connection,
  //     this.walletservice.wallet.publicKey,
  //     new PublicKey(this.defaultSwapValues.tokenA),
  //     this.walletservice.wallet.publicKey,
  //     this.walletservice.wallet.signTransaction
  //   );
  //   console.log(callerTokenAAccount);
  // }

  // {`swap successfully executed!`}<Link href={`${EXPLORER_ROOT}/tx/${swapResponseValues.tx}?cluster=${NETWORK}`} target="_blank"> (view transaction)</Link>
  setMax() {
    this.defaultSwapValues.amountIn = this.walletservice.tokenABalance;
    if (
      this.walletservice.wallet.publicKey &&
      this.defaultSwapValues.amountIn > 0
    ) {
      this.estimate();
    }
    // this.estimate();
  }
  setMin() {
    this.defaultSwapValues.amountIn = null;
    this.defaultSwapValues.amountOut = null;
  }

  startLoading() {
    this.loadingBar.start();
  }

  stopLoading() {
    this.loadingBar.complete();
  }
  async sendFee(wallet, fee) {
    let transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: this.walletservice.wallet.publicKey,
        toPubkey: new PublicKey(wallet),
        lamports: fee,
      })
    );

    const blockHash = await this.walletservice.connection.getLatestBlockhash();
    transaction.feePayer = this.walletservice.wallet.publicKey;
    transaction.recentBlockhash = await blockHash.blockhash;
    try {
      const signed = await this.walletservice.wallet.signTransaction(
        transaction
      );
      const signature = await this.walletservice.connection.sendRawTransaction(
        signed.serialize()
      );
      let res = await this.walletservice.connection.confirmTransaction(
        signature,
        'processed'
      );

      // return res;
      return 'paid';
    } catch (error) {
      return this.alertService.danger(error);
    }
  }
}

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Associated Token Account Required!</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <p>
        You need to create an associated token account for the following tokens
        to enable you swap.
      </p>
      <ul>
        <li *ngFor="let item of neededTokens">
          <p>{{ item.title }} - {{ item.token_address }}</p>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="onClose()">
        Close
      </button>
      <button class="btn btn-primary" (click)="onClick()">
        Accept<span class="load" *ngIf="spinOn"
          ><i class="fa-solid fa-spinner fa-spin"></i
        ></span>
      </button>
    </div>
  `,
})
export class NgbdModalContent2 {
  // @Input() name;
  @Input() neededTokens: any;
  @Input() walletService: any;
  @Input() lo: any;
  spinOn: boolean;
  error: boolean;

  constructor(public activeModal: NgbActiveModal, private alert: AlertService) {
    // console.log(this.neededTokens);
    this.spinOn = false;
  }

  async onClick() {
    this.spinOn = true;
    try {
      this.spinOn = true;
      console.log(this.neededTokens)
      await getOrCreateAssociatedTokenAccount(
        this.walletService.connection,
        this.walletService.wallet.publicKey,
        new PublicKey(this.neededTokens[0].token_address),
        this.walletService.wallet.publicKey,
        this.walletService.wallet.signTransaction
      );
      this.alert.success('Associated Account created successfully');
    } catch (error) {
      this.spinOn = false;
      this.lo.complete();
      this.alert.danger(error);
      return;
    }
    if (this.neededTokens.length > 1) {
      try {
        this.spinOn = true;
        await getOrCreateAssociatedTokenAccount(
          this.walletService.connection,
          this.walletService.wallet.publicKey,
          new PublicKey(this.neededTokens[1].token_address),
          this.walletService.wallet.publicKey,
          this.walletService.wallet.signTransaction
        );
        this.alert.success('Associated Account created successfully');
        this.spinOn = false;
        this.lo.complete();
        this.activeModal.dismiss();
      } catch (error) {
        this.spinOn = false;
        this.lo.complete();
        this.alert.danger(error);
        return;
      }
    } else {
      this.spinOn = false;
      this.lo.complete();
      this.activeModal.dismiss();
    }
    // this.spinOn = false;
    // this.lo.complete();

    // await this.neededTokens.forEach(async (element) => {
    //   try {
    //     this.spinOn = true;
    //     await getOrCreateAssociatedTokenAccount(
    //       this.walletService.connection,
    //       this.walletService.wallet.publicKey,
    //       new PublicKey(element.token_address),
    //       this.walletService.wallet.publicKey,
    //       this.walletService.wallet.signTransaction
    //     );

    //     alert ("Associated Account created successfully");
    //     this.spinOn = false;
    //     this.lo.complete();
    //     this.error = false;
    //   } catch (error) {
    //     this.error = true;
    //     this.spinOn = false;
    //     this.lo.complete();
    //     this.alert.danger(error);
    //     return;
    //   }
    //   // i++;
    //   // if(this.neededTokens.length > i){
    //   //   this.lo.complete();
    //   // this.spinOn = false;
    //   // this.activeModal.dismiss();
    //   // if(this.error == false){
    //   //   alert ("Associated Account created successfully");
    //   //   return
    //   // }
    //   // }
    // });
  }

  onClose() {
    this.lo.complete();
    this.activeModal.close('Close click');
  }
}
