import { Wallet } from '@project-serum/anchor';
import { AppServiceService } from './_services/app-service.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from '@full-fledged/alerts';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { WalletService } from './_services/wallet.service';
import { Clipboard } from '@angular/cdk/clipboard';

import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public isMenuCollapsed = true;
  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly walletIcon$ = this.walletservice.walletIcon$;
  readonly ready$ = this.walletservice.ready$;

  readonly connected$ = this.walletservice.connected$;
  public publicKey$ = this.walletservice.publicKey$;
  garyPrice: number = null;
  coin_symbol: any;
  refresh: NodeJS.Timeout;
  constructor(
    private app: AppServiceService,
    private alertService: AlertService,
    private loadingBar: LoadingBarService,
    private walletservice: WalletService,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard
  ) { }
  ngOnInit() {
    this.walletservice.initialize();
    this.getpricewid();

  }

  ngOnDestroy() {
    if (this.refresh) {
      clearInterval(this.refresh);
    }
  }

  onDisconnect() {
    this.walletservice.onDisconnect();
  }

  onSelectWallet(walletName) {
    this.walletservice.onSelectWallet(walletName);
  }

  truncate(name: any) {
    const name2: string = JSON.stringify(name);
    const a = JSON.parse(name2);

    var truncateRegex =
      /^([1-9A-HJ-NP-Za-km-z]{6})[1-9A-HJ-NP-Za-km-z]+([1-9A-HJ-NP-Za-km-z]{4})$/;
    var match = a.match(truncateRegex);
    // console.log(match);
    if (!match) return name;
    return match[1] + '\u2026' + match[2];
  }

  fixUnsafe(url) {
    //  let newurl =url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  copyText() {
    // console.log(this.walletservice.wallet.publicKey);
    this.clipboard.copy(this.walletservice.wallet.publicKey);
    this.alertService.success('Copied');
  }

  getpricewid() {
    this.app.getGaryPrice().subscribe({
      next: (res: any) => {
        // console.log(res);
        this.coin_symbol = res.data.coin.coin_symbol;
        this.garyPrice = res.data.price_usd.toFixed(4);
      },
    });
  }
}
