<div class="container my-5">
  <div class="row mt-5">
    <div class="col-lg-4 mb-3">
      <div class="card relative">
        <div class="card-body" style="height: 323.95px;" *ngIf="loading2">
          <div class="overlay">
          </div>
        </div>
        <div class="card-body " *ngIf="!loading2">
          <div class="row align-items-center px-2">
            <div class="col-8">
              <h4>Trading info</h4>
            </div>
            <div class="col-4 text-end">
              <button class="btn btn-primary btn-sm" (click)="gotoTrage()">Trade</button>
            </div>
          </div>
          <div class="row align-items-center justify-content-center my-3 px-2">
            <div class="col-3 swapimg" *ngIf="swapdata">
              <img [src]="img" alt="" srcset="">
              <img src="https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg" alt="" srcset="">
            </div>
            <div class="col-7" *ngIf="swapdata">
              <h5>1 {{ id }} = {{ price | number: '1.2-4' }} {{coin_symbol}} </h5>
            </div>

            <div class="col-3 swapimg" *ngIf="!swapdata">
              <img [src]="img" alt="" srcset="">
              <img src="assets/img/dollar.png" alt="" srcset="">
            </div>
            <div class="col-7" *ngIf="!swapdata">
              <h5>1 {{ id }} = $ {{ price_usd | number: '1.2-4' }} </h5>
            </div>

            <div class="col-2">
              <button class="btn btn-clear text-white  btn-sm" (click)="swap()"><i
                  class="fa-solid fa-right-left"></i></button>
            </div>
          </div>



          <div class="row">
            <div class="col-12">
              <p class="light">Token locked</p>
              <div class="card my-3" style="background-color: #b746dafc !important;">
                <div class="card-body">
                  <!-- <div class="row mb-3 align-items-center">
                    <div class="col-6">
                      <h5><span class="me-3"><img [src]="img" width="20" height="20" alt="" srcset=""
                            style="border-radius: 50%;"></span>{{id}}
                      </h5>
                    </div>
                    <div class="col-6 text-end">
                      <h5> {{ coinlocked | number: '1.2-4' }} </h5>
                    </div>
                  </div> -->
                  <div class="row align-items-center">
                    <div class="col-6">
                      <h5><span class="me-3"><img
                            src="https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg" width="20"
                            height="20" alt="" style="border-radius: 50%;"></span>{{  coin_symbol}}
                      </h5>
                    </div>
                    <div class="col-6 text-end">
                      <h5> {{ garylocked | number: '1.2-4' }} </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <p class="light">TVL</p>
              </div>
              <div class="col-6 text-end">
                <h5>${{tvl| number: '1.2-4'}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <p class="light">Volume 24h</p>
              </div>
              <div class="col-6 text-end">
                <h5>$ {{ volume| number: '1.2-4' }} </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8 mb-3">
      <div class="card">
        <div class="card-header p-0 b-0">
          <div class="row">
            <div class="col-md-10">
              <nav ngbNav #nav="ngbNav" class="nav-tabs">
                <ng-container ngbNavItem>
                  <a ngbNavLink>Price</a>
                  <ng-template ngbNavContent>
                    <div class="flex">
                      <div class="flex-item">
                        <div style="display: block;">
                          <canvas baseChart width="400" height="110" [data]="lineChartData" [options]="lineChartOptions"
                            [type]="lineChartType"></canvas>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container ngbNavItem>
                  <a ngbNavLink>Price ($)</a>
                  <ng-template ngbNavContent>
                    <div class="flex">
                      <div class="flex-item">
                        <div style="display: block;">
                          <canvas baseChart width="400" height="110" [data]="lineChartData3"
                            [options]="lineChartOptions" [type]="lineChartType"></canvas>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container ngbNavItem>
                  <a ngbNavLink>Volume</a>
                  <ng-template ngbNavContent>
                    <div class="flex">
                      <div class="flex-item">
                        <div style="display: block;">
                          <canvas baseChart width="400" height="110" [data]="lineChartData4"
                            [options]="lineChartOptions" [type]="lineChartType"></canvas>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container ngbNavItem>
                  <a ngbNavLink>TVL</a>
                  <ng-template ngbNavContent>
                    <div class="flex">
                      <div class="flex-item">
                        <div style="display: block;">
                          <canvas baseChart width="400" height="110" [data]="lineChartData2"
                            [options]="lineChartOptions" [type]="lineChartType"></canvas>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </nav>
            </div>
            <div class="col-md-2 text-end">
              <select [(ngModel)]="model" class="form-select py-3 bg-dark text-white"
                (ngModelChange)="changeDate($event)">
                <!-- <option value="all">All</option> -->
                <option value="today">24 hrs</option>
                <option value="7">7 days</option>
                <option value="30">30 days</option>
                <!-- <option *ngFor="let item of list" [value]="item">{{item}}</option> -->
              </select>
            </div>
          </div>
        </div>
        <div class="card-body" style="height: 265px;" *ngIf="loading3">
          <div class="overlay">
          </div>
        </div>
        <div class="card-body" *ngIf="!loading3">
          <div [ngbNavOutlet]="nav" class="mt-2"></div>

        </div>
      </div>
    </div>
  </div>

  <div class="row my-5">
    <div class="col-12">
      <div class="top">
        <h4>
          Trades
        </h4>
      </div>


      <div class="card">
        <div class="card-body">
          <div class="table-responsive relative">
            <div class="overlay" *ngIf="loading">
            </div>
            <table class="table table-dark table-striped mb-0">
              <thead>
                <tr>
                  <th>Signature</th>
                  <th>By</th>
                  <th>Types</th>
                  <th>Amount</th>
                  <th>Time <i class="fa-solid fa-clock"></i></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data">
                  <td> <a [href]="'https://solscan.io/tx/' + item.signature"
                      target="_blank">{{truncateString(item.signature)}}</a> </td>
                  <td>
                    <a [href]="'https://solscan.io/account/' + item.wallet_address"
                      target="_blank">{{truncateString(item.wallet_address)}}</a>
                  </td>
                  <td>
                    <span *ngIf="item.action === 'buy'">
                      {{  coin_symbol}} &nbsp;<i class="fa-solid fa-arrow-right"></i>&nbsp; {{ id }}
                    </span>
                    <span *ngIf="item.action === 'sell'">
                      {{ id }} &nbsp;<i class="fa-solid fa-arrow-right"></i>&nbsp; {{coin_symbol}}
                    </span>
                  </td>
                  <td> {{ item.amount }}&nbsp;{{ id }} </td>
                  <td> {{ formatDate(item.date_entered)| dateAsAgo }} </td>
                </tr>


              </tbody>
            </table>

            <div class="mt-3">
              <ngb-pagination class="d-flex justify-content-center" [pageSize]="perpage" [collectionSize]="size"
                [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                (pageChange)="getPage($event)"></ngb-pagination>
            </div>
          </div>
        </div>
        <!-- <div class="row mt-2">
          <div class="">
            <ngb-pagination class="d-flex justify-content-center" [pageSize]="perpage" [collectionSize]="size"
              [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
              (pageChange)="getPage($event)"></ngb-pagination>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <br><br><br><br><br>
</div>
