import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class AppServiceService {
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  constructor(private http: HttpClient) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(true);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  getBalance(data) {
    return this.http.post('url', data, httpOptions);
  }

  getAllCoins() {
    return this.http.get(
      environment.base_api + `exchange/coins/` + environment.network
    );
  }

  getJupiterCoins() {
    return this.http.get('https://cache.jup.ag/tokens');
  }

  getTBCTokens() {
    return this.http.get(environment.base_api + `exchange/gettbctokens`);
  }

  getAllSwaps() {
    this.isLoadingSubject.next(true);
    return this.http
      .get(environment.base_api + `exchange/getallswap`)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getAllPrivateCoins() {
    return this.http.get(
      environment.base_api + `exchange/private/coins/` + environment.network
    );
  }

  getSwap(a, b) {
    return this.http.get(
      environment.base_api + `exchange/getswap/${a}/${b}`
    );
  }

  getWormholeData() {
    return this.http.get(environment.base_api + `exchange/wormholeconfig`);
  }

  getGaryPrice() {
    return this.http.get(
      environment.base_api + 'exchange/getcoininfo2/$gary/mainnet'
    );
  }

  getcoinprice(coin) {
    return this.http.get(
      environment.base_api + `exchange/getcoininfo2/${coin}/mainnet`
    );
  }
  getcoinprice2(coin) {
    return this.http.get(
      environment.base_api + `exchange/getcoininfo2/${coin}/mainnet`
    );
  }
  getrpc() {
    return this.http.get(environment.base_api + `exchange/getrpc`);
  }

  checkResc(data) {
    return this.http.get(
      environment.base_api + `exchange/getswaprestrictions/${data}`
    );
  }

  getcoinMarket(sort_key, direction) {
    return this.http.get(
      environment.base_api +
      `exchange/getallcoin?sort_key=${sort_key}&direction=${direction}`
    );
  }

  getTrades(coin, page, sort_key, direction) {
    return this.http.get(
      environment.base_api +
      `exchange/gettrades/${coin}?page=${page}&sort_key=${sort_key}&direction=${direction}`
    );
  }

  getPriceCharts(coin, from, to) {
    return this.http.get(
      environment.base_api + `exchange/getpricechart/${coin}/${from}/${to}`
    );
  }

  getVolumeTraded(coin, from, to) {
    return this.http.get(
      environment.base_api + `exchange/getvolumetraded/${coin}/${from}/${to}`
    );
  }
}
