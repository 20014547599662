import { WalletService } from './../_services/wallet.service';
import { TOKEN_PROGRAM_ID } from '../../utils/index';
import { Token } from '@solana/spl-token';
import { AppServiceService } from '../_services/app-service.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as anchor from "@project-serum/anchor";
import {
  PublicKey,
  SystemProgram,
  Transaction,
  Keypair,
  Connection,
  clusterApiUrl,
  LAMPORTS_PER_SOL,
} from '@solana/web3.js';

import {
  executeSwap,
  estimateSwap,
  tokenSwapProgram,
  getTokenSwapInfo,
  getMintInfo,
  getTokenAccountInfo,
  getOrCreateAssociatedAccount,
  canonicalSwapProgram,
  swapWrappedForCanonical,
  swapCanonicalForWrapped,
} from 'rly-js';

import { getAssociatedTokenAddress, baseToDec, decToBase } from '../../utils';
import { getOrCreateAssociatedTokenAccount } from '../../utils/getOrCreateAssociatedTokenAccount';
import BN from 'bn.js';
import { EXPLORER_ROOT, NETWORK } from '../../config';
// import { Provider } from 'rly-js/node_modules/@project-serum/anchor';
import { web3, Provider } from '@project-serum/anchor'
import { AlertService } from '@full-fledged/alerts';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { environment } from 'src/environments/environment';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { getAccountInfo } from 'src/utils/getAccountInfo';
// import { environment } from '../../environments';

declare interface coinInfo {
  img: string;
  name: string;
  token_address: string;
}
type defaultSwapValues = {
  tokenSwapInfo: string;
  tokenA: string;
  tokenB: string;
  amountIn: number;
  amountOut: number;
  typeA: string;
  typeB: string;
};
type swapResponse = {
  tx: string | null;
};

const cswap = {
  canonicalMintV3: 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh',
  canonicalDataV3: '97exq8nMj13ydaQAwxMN26nfHXtvoVioexYkoB1ZUs2v',
  canonicalMintV2: 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq',
  canonicalDataV2: '61SVmuBgYEChu3vBR4PXtTzHbXYsn78CM7uZCSquieHu',
  wormholeMint: '6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4',
  wormholeData: 'FvdfyPydxRgCnFPwBdrx7B1fuBAZFxWCahATumYvzEdv',
  wormholeData1: 'BuvUZWrTnrBkacCikXsoGW1zA1yMt7D1okq3ZDJrDft8'
}

const tdata = {
'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh': '97exq8nMj13ydaQAwxMN26nfHXtvoVioexYkoB1ZUs2v',
'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq': '61SVmuBgYEChu3vBR4PXtTzHbXYsn78CM7uZCSquieHu',
'6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4': 'FvdfyPydxRgCnFPwBdrx7B1fuBAZFxWCahATumYvzEdv',
'wormholev1': 'BuvUZWrTnrBkacCikXsoGW1zA1yMt7D1okq3ZDJrDft8'
};
// const canonicalMint = new PublicKey(
//   'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq'
// );

// const canonicalData = new PublicKey(
//   '4wkz5UF7ziY3Kuz1vxkZBakcZrRoTfup4XPASdhDfnpk'
// );

// const wormholeMint = new PublicKey(
//   '6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4'
// );

// const wormholeData = new PublicKey(
//   'BuvUZWrTnrBkacCikXsoGW1zA1yMt7D1okq3ZDJrDft8'
// );

const ten = new BN(10);
// const defaultSwapValues = {
//   tokenSwapInfo: '',
//   tokenA: '',
//   tokenB: '',
//   amountIn: 0,
//   amountOut: 0,
// } as defaultSwapValues;

const defaultSwapResponse = {} as swapResponse;

// export const coin: coinInfo[] = [
//   {
//     img: 'assets/img/dollar.png',
//     name: '$SRLY',
//     token_address: '3ZLUkydTzCsrKV3DShdHFr8xZALnHPCqfNCjMDtWS6uC',
//   },
//   {
//     img: 'assets/img/solana.png',
//     name: '$GARY',
//     token_address: 'CWv3gBHKZ3EaCfFzEbAwp7ep7j6PAgAm11hx2Su3XYFt',
//   },
// ];
@Component({
  selector: 'app-wormhole',
  templateUrl: './wormhole.component.html',
  styleUrls: ['./wormhole.component.scss'],
})
export class WormholeComponent implements OnInit {
  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly connected$ = this.walletservice.connected$;
  readonly publicKey$ = this.walletservice.publicKey$;
  lamports = 0;
  swapResponseValues: swapResponse;
  recipient = '';

  title = 'Select Coin';
  img = '';
  coin2 = 'Select Coin';
  img2 = '';
  public isCollapsed = true;
  public isCollapsed2 = true;
  public menuItems: any[] = [];
  public menuItems2: any[] = [];
  public estimateOut = 0;
  public tokenABalance = this.walletservice.tokenABalance;
  public tokenBBalance = this.walletservice.tokenBBalance;
  setEstimateOut = 0;
  balance = 0;
  disablebtn = true;
  public defaultSwapValues: defaultSwapValues = {
    tokenSwapInfo: '',
    tokenA: '',
    tokenB: '',
    amountIn: null,
    amountOut: null,
    typeA: null,
    typeB: null,
  };
  public swapDetails = {
    swapId: '',
    tokenA: '',
    tokenB: '',
  };
  // connection = new Connection(environment.solana_api, {
  //   commitment: 'confirmed',
  //   wsEndpoint: environment.solana_api_ws,
  //   confirmTransactionInitialTimeout: 120000,
  // });
  // connection = this.walletservice.connection;
  // wallet = this._hdWalletStore.aanchorWallet$ as Wallet;

  // console.log(this.wallet);
  provider: any;
  wallet: any;
  alert: string = null;
  tnxlink: string;
  refresh: NodeJS.Timeout;
  menuItems4: any;
  wormhole_fee: any;
  wormhole_address: any;
  spinOn = false;
  noswap = false;
  neededTokens: any[];
  serror: boolean;
  canSwap: boolean;
  canswapMessage: any;
  refresh3: NodeJS.Timeout;
  exchange_message: any;
  signature: any;
  //  = new AnchorProvider(this.connection, this.wallet, {});
  // provider: Provider = new Provider(this.connection, this.wallet, {});
  constructor(
    private app: AppServiceService,
    private alertService: AlertService,
    private loadingBar: LoadingBarService,
    public walletservice: WalletService,
    private modalService: NgbModal,
    config: NgbModalConfig,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.app.getAllCoins().subscribe({
      next: (res: any) => {
        // console.log(res);
        this.menuItems4 = [
          // {
          //   img: 'assets/img/dollar.png',
          //   name: '$WormHoleRLYv1',
          //   coin_symbol: '$WormHoleRLYv1',
          //   token_address: '6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4',
          //   type: "v1"
          // },
          {
            img: 'assets/img/dollar.png',
            name: '$WormHoleRLY',
            coin_symbol: '$WormHoleRLY',
            token_address: '6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4',
            type: "v2"
          },
          {
            img: 'assets/img/solana.png',
            name: 'sRLYv2',
            coin_symbol: '$sRLYv2',
            token_address: 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq',
            type: "v2"
          },
          {
            img: 'assets/img/solana.png',
            name: 'sRLYv3',
            coin_symbol: '$sRLYv3',
            token_address: 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh',
            type: "v3"
          }
        ];

        this.menuItems = this.menuItems4;
        this.menuItems2 = this.menuItems4;
        // console.log(this.menuItems);
      },
    });

    // this.provider = new Provider(
    //   this.walletservice.connection,
    //   this.walletservice.wallet,
    //   {}
    // );
    // const a = this._hdWalletStore.anchorWallet$.subscribe();
    // console.log(a);
    // 'Wallet': signTransaction, signAllTransactions, publicKey
  }

  estimate1(event) {
    // this.spinOn = true
    this.defaultSwapValues.amountIn = event;
    if (
      this.walletservice.wallet.publicKey &&
      this.defaultSwapValues.amountIn > 0
    ) {
      this.estimate();
    }
  }
  async selectCoin(name: any, img: any, token_address,type) {
    this.isCollapsed = true;
    this.title = name;
    this.img = img;
    this.defaultSwapValues.tokenA = token_address;
    this.defaultSwapValues.typeA = type;
    this.menuItems2 = [];
    for (let i = 0; i < this.menuItems4.length; i++) {
      const element = this.menuItems4[i];
      if (
        token_address === 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq'
      ) {
        if(element.token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh'){
          this.menuItems2.push(element);
        }
      } else if(token_address == '6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4') {
        if(element.coin_symbol != name && element.token_address != 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq'){
          this.menuItems2.push(element);
        }
      }else if (token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh'){
        if(element.coin_symbol != name){
          this.menuItems2.push(element);
        }
      }

    }
    // for (let i = 0; i < this.menuItems4.length; i++) {
    //   const element = this.menuItems4[i];
    //   if (element.coin_symbol !== name) {
    //     // console.log(element);
    //     this.menuItems2.push(element);
    //   }
    // }
    const callerTokenAAccount = await getAssociatedTokenAddress(
      new PublicKey(token_address),
      this.walletservice.wallet.publicKey
    );
    // console.log('hey ' + callerTokenAAccount);
    try {
      this.walletservice.tokenABalance = (
        await this.walletservice.connection.getTokenAccountBalance(callerTokenAAccount)
      ).value.uiAmount;
      // alert(this.walletservice.tokenABalance)
      this.walletservice.tokenABalance = parseInt(
        this.walletservice.tokenABalance.toString()
      );
    } catch (error) {
      this.walletservice.tokenABalance = 0;
    }

    // this.defaultSwapValues.tokenA = name;
    // const data = {
    //   key: this.publicKey$,
    //   address: token_address,
    // };
    // this.app.getBalance(data).subscribe({
    //   next: (res: any) => {
    //     console.log(res);
    //     this.balance = res.data;
    //   },
    // });
    // this.publicKey$, token_address
  }
  async estimate() {
    this.spinOn = true;

    this.defaultSwapValues.amountOut = this.defaultSwapValues.amountIn;
    this.spinOn = false;
  }
  // if (wallet.publicKey && formValues.amountIn > 0) {
  //     estimate()
  // }

  async anothercoin(name2: any, img2: any, token_address,type) {
    this.isCollapsed2 = true;
    this.coin2 = name2;
    this.img2 = img2;
    this.defaultSwapValues.tokenB = token_address;
    this.defaultSwapValues.typeB = type;
    this.menuItems = [];
    for (let i = 0; i < this.menuItems4.length; i++) {
      const element = this.menuItems4[i];
      if (
        token_address === 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq'
      ) {
        if(element.token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh'){
          this.menuItems.push(element);
        }
      } else if(token_address == '6Y7LNYkHiJHSH8zR2HvZQzXD3QA9yFw64tyMHxBxDRe4') {
        if(element.coin_symbol != name2 && element.token_address != 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq'){
          this.menuItems.push(element);
        }
      }else if (token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh'){
        if(element.coin_symbol != name2){
          this.menuItems.push(element);
        }
      }

    }
    // for (let i = 0; i < this.menuItems4.length; i++) {
    //   const element = this.menuItems4[i];
    //   if (element.coin_symbol !== name2) {
    //     // console.log(element);
    //     this.menuItems.push(element);
    //   }
    // }
    // this.app
    //   .getSwap(this.defaultSwapValues.tokenA, this.defaultSwapValues.tokenB)
    //   .subscribe({
    //     next: (res: any) => {
    //       // console.log(res);
    //       if (res.data !== null) {
    //         this.swapDetails.swapId = res.data.swap_id;
    //         this.swapDetails.tokenA = res.data.tokena_address;
    //         this.swapDetails.tokenB = res.data.tokenb_address;
    //       }
    //     },
    //   });
    // console.log('coin2', this.coin2);
    const callerTokenBAccount = await getAssociatedTokenAddress(
      new PublicKey(token_address),
      this.walletservice.wallet.publicKey
    );

    try {
      this.walletservice.tokenBBalance = (
        await this.walletservice.connection.getTokenAccountBalance(callerTokenBAccount)
      ).value.uiAmount;
      this.walletservice.tokenBBalance = parseInt(
        this.walletservice.tokenBBalance.toString()
      );
    } catch (error) {
      this.walletservice.tokenBBalance = 0;
    }

    // this.defaultSwapValues.tokenB = name2;
  }

  async swap() {
    const a = this.title;
    const b = this.coin2;
    const c = this.img;
    const d = this.img2;

    const title1 = this.title;
    const img1 = this.img;
    const token_address1 = this.defaultSwapValues.tokenA;

    const title2 = this.coin2;
    const img2 = this.img2;
    const token_address2 = this.defaultSwapValues.tokenB;

    await this.selectCoin(title2, img2, token_address2,this.defaultSwapValues.typeB);
    await this.anothercoin(title1, img1, token_address1,this.defaultSwapValues.typeA);
    this.defaultSwapValues.amountIn = 0;
    this.defaultSwapValues.amountOut = 0;
    // this.title = b;
    // this.coin2 = a;
    // this.img = d;
    // this.img2 = c;
  }
  ngOnInit() {
    this.app.getWormholeData().subscribe({
      next: (res: any) => {
        this.wormhole_fee = res.data.wormhole_fee.config_value;
        this.wormhole_address = res.data.wormhole_address.config_value;
      },
    });
    // this.refresh3 = setInterval(() => {
    //   console.log('checking');
    //   if (this.walletservice.exchange_wormhole_message !== undefined) {
    //     if (this.walletservice.exchange_wormhole_message.length > 1) {
    //       this.exchange_message = this.walletservice.exchange_wormhole_message;
    //     }
    //     clearInterval(this.refresh3);
    //   }
    // }, 5000);
    this.walletservice.resetBalance();
    // this._hdConnectionStore.setEndpoint('https://solana-api.projectserum.com');
  }

  async handleSubmit(e: any) {
    this.startLoading();
    this.noswap = true;
    this.spinOn = true;
    e.preventDefault();
    if (
      this.walletservice.exchange_wormhole_status == 'offline' ||
      this.canSwap == false
    ) {
      if (this.walletservice.exchange_wormhole_status == 'offline') {
        this.alertService.warning(this.walletservice.exchange_wormhole_offline_message);
      } else if (this.canSwap == false) {
        this.alertService.warning(this.canswapMessage);
      }
      this.noswap = false;
      this.spinOn = false;
      return;
    }
    if (!this.walletservice.wallet.publicKey) {
      this.alertService.danger('wallet not active');
      // this.stopLoading();
      this.noswap = false;
      this.spinOn = false;
    } else {
      try {
        const feeAdd = this.wormhole_address;
        const fee = parseFloat(this.wormhole_fee) * LAMPORTS_PER_SOL;
        const sbalance = await this.walletservice.connection.getBalance(
          this.walletservice.wallet.publicKey
        );
        if (sbalance < fee) {
          this.stopLoading();
          this.noswap = false;
          this.spinOn = false;
          return this.alertService.danger(
            'You do not have sufficient Sol to complete swap'
          );
        }
        const wallet = this.walletservice.wallet;
        const connection = this.walletservice.connection;
        const provider = this.walletservice.provider;
        const canSwap = await canonicalSwapProgram(provider);
        let decimals;
        // const wormholeToken = new Token(
        //   connection,
        //   new PublicKey(wormholeMint),
        //   TOKEN_PROGRAM_ID,
        //   this.walletservice.wallet
        // );
        // const canonicalToken = new Token(
        //   connection,
        //   new PublicKey(canonicalMint),
        //   TOKEN_PROGRAM_ID,
        //   this.walletservice.wallet
        // );
        let aDec;
        let bDec;
        let Adata;
        let Bdata;
        ({ decimals: aDec } = await getMintInfo({
          tokenMint: new PublicKey(this.defaultSwapValues.tokenA),
          connection,
        }));
        // alert(aDec);
        ({ decimals: bDec } = await getMintInfo({
          tokenMint: new PublicKey(this.defaultSwapValues.tokenB),
          connection,
        }));
        // alert(bDec);
        // let t = this.defaultSwapValues.tokenA.toString();
        // type ObjectKey = keyof typeof tdata;
        // const ta = this.defaultSwapValues.tokenA as ObjectKey;
        if(this.defaultSwapValues.tokenA == cswap.wormholeMint && this.defaultSwapValues.typeA == 'v1'){
          Adata = new PublicKey(tdata['wormholev1']);
        }else{
          Adata = new PublicKey(tdata[this.defaultSwapValues.tokenA]);
        }

        if(this.defaultSwapValues.tokenB == cswap.wormholeMint && this.defaultSwapValues.typeB == 'v1'){
          Bdata = new PublicKey(tdata['wormholev1']);
        }else{
          Bdata = new PublicKey(tdata[this.defaultSwapValues.tokenB]);
        }
        // alert(Adata)

        // alert(Bdata)
        if(this.defaultSwapValues.tokenA == cswap.wormholeMint){
          ({ decimals } = await canSwap.account.canonicalData.fetch(Bdata));
        }else if(this.defaultSwapValues.tokenB == cswap.wormholeMint){
          ({ decimals } = await canSwap.account.wrappedData.fetch(Bdata));
        }else{
          ({ decimals } = await canSwap.account.canonicalData.fetch(Bdata));
        }
        // alert(decimals);


        const ten = new BN(10);
        decimals = new BN(decimals);
        let destAmount = new BN(this.defaultSwapValues.amountIn);
        let callerTokenAAccount, callerTokenBAccount;
        this.neededTokens = [];
        //convert to decimal units
        destAmount = destAmount.mul(ten.pow(decimals));
        try {
          const associatedTokenA = await getAssociatedTokenAddress(
            new PublicKey(this.defaultSwapValues.tokenA),
            this.walletservice.wallet.publicKey
          );


          const associatedTokenB = await getAssociatedTokenAddress(
            new PublicKey(this.defaultSwapValues.tokenB),
            this.walletservice.wallet.publicKey
          );

          //  this. serror = false;
          //  error = false

          try {
            callerTokenAAccount = await getAccountInfo(
              this.walletservice.connection,
              associatedTokenA,
              'confirmed',
              TOKEN_PROGRAM_ID
            );
            // alert(callerTokenAAccount)
          } catch (error) {
            alert(1);
            if (
              error.message === 'TokenAccountNotFoundError' ||
              error.message === 'TokenInvalidAccountOwnerError'
            ) {
              this.neededTokens.push({
                title: this.title,
                token_address: this.defaultSwapValues.tokenA,
              });
              this.serror = true;
            }
            callerTokenAAccount
          }
          try {
            callerTokenBAccount = await getAccountInfo(
              this.walletservice.connection,
              associatedTokenB,
              'confirmed',
              TOKEN_PROGRAM_ID
            );
          } catch (error) {
            if (
              error.message === 'TokenAccountNotFoundError' ||
              error.message === 'TokenInvalidAccountOwnerError'
            ) {
              this.neededTokens.push({
                title: this.coin2,
                token_address: this.defaultSwapValues.tokenB,
              });
              this.serror = true;
            }
          }

          if (this.serror == true) {
            throw 'error';
          }
          callerTokenAAccount = callerTokenAAccount.address;
          callerTokenBAccount = callerTokenBAccount.address;

          let balance;
          let signature;
          let signedTxn = [];
          let response;
                let tx = new Transaction();
          // let callerTokenAAccount, callerTokenBAccount;

          let { amount: tokenABalance } = (
            await this.walletservice.connection.getTokenAccountBalance(callerTokenAAccount)
          ).value;
          balance = new BN(tokenABalance)
              .div(ten.pow(new BN(aDec)))
              .toNumber();
              if (balance < Number(this.defaultSwapValues.amountIn)) {
                this.stopLoading();
                this.noswap = false;
                return this.alertService.danger(
                  `insufficent funds, your  balance is currently ${balance} `
                );
              }

              if (this.walletservice.wallet.publicKey != feeAdd && fee > 0 && (this.walletservice.wallet.publicKey != 'F5shWjKpZCCpvZkwRXHWqZs5aMtRgkVEXgya83TYpNx' &&
              this.walletservice.wallet.publicKey != 'DuGjnPTVf4B84ssCqFRP12hBtNS1tucUGyzavTnwrH2F' &&  this.walletservice.wallet.publicKey != 'BZ85wfcaBy5PCdfZhP2ZGL6PD46D2Ya5NcMKiiXCuYWu' )) {
                let r = await this.sendFee(feeAdd, fee);

                if (r != 'paid') {
                  this.stopLoading();
                  this.noswap = false;
                  this.spinOn = false;
                  return;
                }
              }

              if(this.defaultSwapValues.tokenA == cswap.wormholeMint){
                 // tx = await swapWrappedForCanonical({
                //   canSwap,
                //   canonicalMint: new PublicKey(cswap.canonicalMintV3),
                //   wrappedMint: new PublicKey(cswap.wormholeMint),
                //   canonicalData: new PublicKey(cswap.canonicalDataV3),
                //   wrappedData: new PublicKey(cswap.wormholeData),
                //   sourceTokenAccount: new PublicKey(callerTokenAAccount),
                //   destinationTokenAccount: new PublicKey(callerTokenBAccount),
                //   destinationAmount: destAmount,
                //   wallet,
                //   connection,
                // });
                const [expectedMintAuthorityPDA] = await web3.PublicKey.findProgramAddress(
                  [anchor.utils.bytes.utf8.encode("can_mint_authority"), new PublicKey(cswap.canonicalMintV3).toBuffer()],
                  canSwap.programId
                );
                const [wrappedTokenAccount] = await PublicKey.findProgramAddress(
                  [anchor.utils.bytes.utf8.encode("token_account_seed"), new PublicKey(cswap.canonicalMintV3).toBuffer(), new PublicKey(cswap.wormholeMint).toBuffer()],
                  canSwap.programId
                );
                const [wrappedTokenAccountAuthority] = await PublicKey.findProgramAddress(
                  [
                    anchor.utils.bytes.utf8.encode(
                      "wrapped_acct_authority"
                    ),
                    new PublicKey(cswap.canonicalMintV3).toBuffer(),
                    new PublicKey(cswap.wormholeMint).toBuffer(),
                  ],
                  canSwap.programId
                );


                const ix = canSwap.instruction.swapWrappedForCanonical(destAmount, {
                  accounts: {
                    user: wallet.publicKey,
                    destinationCanonicalTokenAccount: new PublicKey(callerTokenBAccount),
                    canonicalMint: new PublicKey(cswap.canonicalMintV3),
                    pdaCanonicalMintAuthority: expectedMintAuthorityPDA,
                    sourceWrappedTokenAccount: new PublicKey(callerTokenAAccount),
                    wrappedTokenAccount,
                    canonicalData: new PublicKey(cswap.canonicalDataV3),
                    wrappedData: new PublicKey(cswap.wormholeData),
                    tokenProgram: TOKEN_PROGRAM_ID,
                  },
                });



                tx.add(ix);
                let blockHash = await this.walletservice.connection.getLatestBlockhash();
                tx.feePayer = this.walletservice.wallet.publicKey;
                // alert(tx.feePayer)
                tx.recentBlockhash = await blockHash.blockhash;
                signedTxn.push(tx);

              }else if(this.defaultSwapValues.tokenB != cswap.wormholeMint){
              //   tx = await swapWrappedForCanonical({
              //     canSwap,
              //     canonicalMint: new PublicKey(cswap.canonicalMintV3),
              //     wrappedMint: new PublicKey(cswap.canonicalMintV2),
              //     canonicalData: new PublicKey(cswap.canonicalDataV3),
              //     wrappedData: new PublicKey(cswap.canonicalDataV2),
              //     sourceTokenAccount: new PublicKey(callerTokenAAccount),
              //     destinationTokenAccount: new PublicKey(callerTokenBAccount),
              //     destinationAmount: destAmount,
              //     wallet,
              //     connection

              const [expectedMintAuthorityPDA] = await web3.PublicKey.findProgramAddress(
                [anchor.utils.bytes.utf8.encode("can_mint_authority"), new PublicKey(cswap.canonicalMintV3).toBuffer()],
                canSwap.programId
              );
              const [wrappedTokenAccount] = await PublicKey.findProgramAddress(
                [anchor.utils.bytes.utf8.encode("token_account_seed"), new PublicKey(cswap.canonicalMintV3).toBuffer(), new PublicKey(cswap.canonicalMintV2).toBuffer()],
                canSwap.programId
              );
              const [wrappedTokenAccountAuthority] = await PublicKey.findProgramAddress(
                [
                  anchor.utils.bytes.utf8.encode(
                    "wrapped_acct_authority"
                  ),
                  new PublicKey(cswap.canonicalMintV2).toBuffer(),
                  new PublicKey(cswap.canonicalMintV3).toBuffer(),
                ],
                canSwap.programId
              );


              const ix = canSwap.instruction.swapWrappedForCanonical(destAmount, {
                accounts: {
                  user: wallet.publicKey,
                  destinationCanonicalTokenAccount: new PublicKey(callerTokenBAccount),
                  canonicalMint: new PublicKey(cswap.canonicalMintV3),
                  pdaCanonicalMintAuthority: expectedMintAuthorityPDA,
                  sourceWrappedTokenAccount: new PublicKey(callerTokenAAccount),
                  wrappedTokenAccount,
                  canonicalData: new PublicKey(cswap.canonicalDataV3),
                  wrappedData: new PublicKey(cswap.canonicalDataV2),
                  tokenProgram: TOKEN_PROGRAM_ID,
                },
              });



              tx.add(ix);
              let blockHash = await this.walletservice.connection.getLatestBlockhash();
              tx.feePayer = this.walletservice.wallet.publicKey;
              // alert(tx.feePayer)
              tx.recentBlockhash = await blockHash.blockhash;
              signedTxn.push(tx);
              }else{


                const transaction = new Transaction();
                const [wrappedTokenAccount] = await PublicKey.findProgramAddress(
                  [anchor.utils.bytes.utf8.encode("token_account_seed"), new PublicKey(cswap.canonicalMintV3).toBuffer(), new PublicKey(cswap.wormholeMint).toBuffer()],
                  canSwap.programId
                );
                const [wrappedTokenAccountAuthority] = await PublicKey.findProgramAddress(
                  [
                    anchor.utils.bytes.utf8.encode(
                      "wrapped_acct_authority"
                    ),
                    new PublicKey(cswap.canonicalMintV3).toBuffer(),
                    new PublicKey(cswap.wormholeMint).toBuffer(),
                  ],
                  canSwap.programId
                );

                const ix = canSwap.instruction.swapCanonicalForWrapped(destAmount, {
                    accounts: {
                        user: wallet.publicKey,
                        sourceCanonicalTokenAccount:  new PublicKey(callerTokenAAccount),
                        canonicalMint: new PublicKey(cswap.canonicalMintV3),
                        destinationWrappedTokenAccount:new PublicKey(callerTokenBAccount),
                        wrappedTokenAccount,
                        pdaWrappedTokenAuthority: wrappedTokenAccountAuthority,
                        canonicalData: new PublicKey(cswap.canonicalDataV3),
                        wrappedData: new PublicKey(cswap.wormholeData),
                        tokenProgram: TOKEN_PROGRAM_ID,
                    },
                });

                // tx = await swapCanonicalForWrapped({
                //   canSwap,
                //   canonicalMint: new PublicKey(cswap.canonicalMintV3),
                //   wrappedMint: new PublicKey(cswap.wormholeMint),
                //   canonicalData: new PublicKey(cswap.canonicalDataV3),
                //   wrappedData: new PublicKey(cswap.wormholeData),
                //   sourceTokenAccount: new PublicKey(callerTokenAAccount),
                //   destinationTokenAccount: new PublicKey(callerTokenBAccount),
                //   destinationAmount: destAmount,
                //   wallet,
                //   connection,
                // });

                tx.add(ix);
                let blockHash = await this.walletservice.connection.getLatestBlockhash();
                tx.feePayer = this.walletservice.wallet.publicKey;
                // alert(tx.feePayer)
                tx.recentBlockhash = await blockHash.blockhash;
                signedTxn.push(tx);

              }
              const signed = await this.walletservice.wallet.signAllTransactions(signedTxn);
          for (let singleTxn of signed) {
            // await signed.forEach(async (singleTxn) => {
            // console.log(singleTxn)
            signature = await this.walletservice.connection.sendRawTransaction(
              singleTxn.serialize()
            );
            console.log(signature)
            response = await this.walletservice.connection.confirmTransaction(
              signature,
              'confirmed'
            );
            // console.log(signed);
            this.signature = signature;
          }


              this.refresh = setInterval(async () => {
                let a = (
                  await this.walletservice.connection.getTokenAccountBalance(callerTokenAAccount)
                ).value.uiAmount;
                a = parseInt(a.toString());
                let b = (
                  await this.walletservice.connection.getTokenAccountBalance(callerTokenBAccount)
                ).value.uiAmount;
                b = parseInt(b.toString());
                if (a !== this.walletservice.tokenABalance) {
                  this.walletservice.tokenABalance = a;
                  this.walletservice.tokenBBalance = b;
                  clearInterval(this.refresh);
                }
              }, 5000);

              this.swapResponseValues = { tx: this.signature };
              this.tnxlink = `${EXPLORER_ROOT}/tx/${this.swapResponseValues.tx}`;
              this.alert = `swap successfully executed! `;
              const html = `
              <h4>${this.alert}</h4>
             <a href=${this.tnxlink} target="_blank">view transaction</a>`;
              this.alertService.success({ html });
              this.defaultSwapValues.amountIn = 0;
              this.defaultSwapValues.amountOut = 0;
              // console.log(callerTokenAAccount);
              this.stopLoading();
              this.noswap = false;
              this.spinOn = false;
        }catch (error) {

          // console.log(this.neededTokens);
          if(this.neededTokens.length > 0){
            const modalRef = this.modalService.open(NgbdModalContent1);
            // this.modalService.open(NgbdModalContent).result;
            // this.stopLoading();
            modalRef.componentInstance.neededTokens = this.neededTokens;
            modalRef.componentInstance.walletService = this.walletservice;
            modalRef.componentInstance.lo = this.loadingBar;
            this.noswap = false;
            this.spinOn = false;
            status = 'err';
          }else{
            alert(error);
            this.spinOn = false;
            this.noswap = false;
            this.spinOn = false;
            status = 'err';
            this.stopLoading();
          }



        }

          // callerTokenAAccount = callerTokenAAccount.address;
          // callerTokenBAccount = callerTokenBAccount.address;
        // const callerTokenAAccount = await getOrCreateAssociatedTokenAccount(
        //   this.walletservice.connection,
        //   this.walletservice.wallet.publicKey,
        //   new PublicKey(this.defaultSwapValues.tokenA),
        //   this.walletservice.wallet.publicKey,
        //   this.walletservice.wallet.signTransaction
        // );
        // const callerTokenBAccount = await getOrCreateAssociatedTokenAccount(
        //   this.walletservice.connection,
        //   this.walletservice.wallet.publicKey,
        //   new PublicKey(this.defaultSwapValues.tokenB),
        //   this.walletservice.wallet.publicKey,
        //   this.walletservice.wallet.signTransaction
        // );
        // alert("A" +callerTokenAAccount);
        // alert("B" +callerTokenBAccount);


            // alert(destAmount);




      } catch (error) {
        this.alertService.danger(error);
        this.stopLoading();
        this.noswap = false;
        this.spinOn = false;
      }
    }
  }
  // async test() {
  //   // const a = new Token(this.connection,new PublicKey(this.defaultSwapValues.tokenA),TOKEN_PROGRAM_ID,this.wallet);

  //   const callerTokenAAccount = await getOrCreateAssociatedTokenAccount(
  //     this.connection,
  //     this.walletservice.wallet.publicKey,
  //     new PublicKey(this.defaultSwapValues.tokenA),
  //     this.walletservice.wallet.publicKey,
  //     this.walletservice.wallet.signTransaction
  //   );
  //   console.log(callerTokenAAccount);
  // }

  // {`swap successfully executed!`}<Link href={`${EXPLORER_ROOT}/tx/${swapResponseValues.tx}?cluster=${NETWORK}`} target="_blank"> (view transaction)</Link>
  setMax() {
    this.defaultSwapValues.amountIn = this.walletservice.tokenABalance;
    if (
      this.walletservice.wallet.publicKey &&
      this.defaultSwapValues.amountIn > 0
    ) {
      this.estimate();
    }
    // this.estimate();
  }
  setMin() {
    this.defaultSwapValues.amountIn = null;
    this.defaultSwapValues.amountOut = null;
  }

  startLoading() {
    this.loadingBar.start();
  }

  stopLoading() {
    this.loadingBar.complete();
  }
  async sendFee(wallet, fee) {
    let transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: this.walletservice.wallet.publicKey,
        toPubkey: new PublicKey(wallet),
        lamports: fee,
      })
    );

    const blockHash = await this.walletservice.connection.getLatestBlockhash();
    transaction.feePayer = this.walletservice.wallet.publicKey;
    transaction.recentBlockhash = await blockHash.blockhash;
    try {
      const signed = await this.walletservice.wallet.signTransaction(
        transaction
      );
      const signature = await this.walletservice.connection.sendRawTransaction(
        signed.serialize()
      );
      let res = await this.walletservice.connection.confirmTransaction(
        signature,
        'processed'
      );

      // return res;
      return 'paid';
    } catch (error) {
      return this.alertService.danger(error);
    }
  }
}



@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Associated Token Account Required!</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <p>
        You need to create an associated token account for the following tokens
        to enable you swap.
      </p>
      <ul>
        <li *ngFor="let item of neededTokens">
          <p>{{ item.title }} - {{ item.token_address }}</p>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="onClose()"
      >
        Close
      </button>
      <button class="btn btn-primary" (click)="onClick()">Accept<span class="load" *ngIf="spinOn"><i class="fa-solid fa-spinner fa-spin"></i></span></button>
    </div>
  `,
})
export class NgbdModalContent1 {
  // @Input() name;
  @Input() neededTokens: any;
  @Input() walletService: any;
  @Input() lo: any;
  spinOn : boolean;
  error: boolean;

  constructor(public activeModal: NgbActiveModal, private alert: AlertService) {
    // console.log(this.neededTokens);
    this.spinOn = false;
  }

  async onClick() {
    this.spinOn = true;
    try {
      this.spinOn = true;
      await getOrCreateAssociatedTokenAccount(
        this.walletService.connection,
        this.walletService.wallet.publicKey,
        new PublicKey(this.neededTokens[0].token_address),
        this.walletService.wallet.publicKey,
        this.walletService.wallet.signTransaction
      );
      this.alert.success("Associated Account created successfully");
    } catch (error) {
      this.spinOn = false;
        this.lo.complete();
        this.alert.danger(error);
        return;
    }
    if(this.neededTokens.length > 1){
      try {
        this.spinOn = true;
        await getOrCreateAssociatedTokenAccount(
          this.walletService.connection,
          this.walletService.wallet.publicKey,
          new PublicKey(this.neededTokens[1].token_address),
          this.walletService.wallet.publicKey,
          this.walletService.wallet.signTransaction
        );
        this.alert.success("Associated Account created successfully");
        this.spinOn = false;
        this.lo.complete();
        this.activeModal.dismiss();
      } catch (error) {
        this.spinOn = false;
        this.lo.complete();
        this.alert.danger(error);
        return;
      }
    }else{
      this.spinOn = false;
        this.lo.complete();
        this.activeModal.dismiss();
    }
    // this.spinOn = false;
    // this.lo.complete();

    // await this.neededTokens.forEach(async (element) => {
    //   try {
    //     this.spinOn = true;
    //     await getOrCreateAssociatedTokenAccount(
    //       this.walletService.connection,
    //       this.walletService.wallet.publicKey,
    //       new PublicKey(element.token_address),
    //       this.walletService.wallet.publicKey,
    //       this.walletService.wallet.signTransaction
    //     );

    //     alert ("Associated Account created successfully");
    //     this.spinOn = false;
    //     this.lo.complete();
    //     this.error = false;
    //   } catch (error) {
    //     this.error = true;
    //     this.spinOn = false;
    //     this.lo.complete();
    //     this.alert.danger(error);
    //     return;
    //   }
    //   // i++;
    //   // if(this.neededTokens.length > i){
    //   //   this.lo.complete();
    //   // this.spinOn = false;
    //   // this.activeModal.dismiss();
    //   // if(this.error == false){
    //   //   alert ("Associated Account created successfully");
    //   //   return
    //   // }
    //   // }
    // });



  }

  onClose(){

    this.lo.complete();
    this.activeModal.close('Close click')
  }
}
