import { WalletService } from './../_services/wallet.service';
import { AppServiceService } from '../_services/app-service.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import {
//   WalletAdapterNetwork,
//   WalletName,
//   WalletReadyState,
// } from '@solana/wallet-adapter-base';
// import {
//   LedgerWalletAdapter,
//   PhantomWalletAdapter,
//   SlopeWalletAdapter,
//   SolflareWalletAdapter,
//   SolletExtensionWalletAdapter,
//   SolletWalletAdapter,
//   TorusWalletAdapter,
// } from '@solana/wallet-adapter-wallets';
// import { ConnectionStore, WalletStore } from '@heavy-duty/wallet-adapter';
import { PublicKey } from '@solana/web3.js';
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from '@solana/spl-token';

import {
  executeSwap,
  estimateSwap,
  tokenSwapProgram,
  getTokenSwapInfo,
  getMintInfo,
  getTokenAccountInfo,
  getOrCreateAssociatedAccount,
} from 'rly-js';
import { AnchorProvider, Wallet } from '@project-serum/anchor';
import * as anchor from '@project-serum/anchor';

import { getAssociatedTokenAddress, baseToDec, decToBase } from '../../utils';
import { getOrCreateAssociatedTokenAccount } from '../../utils/getOrCreateAssociatedTokenAccount';
import BN from 'bn.js';
import { EXPLORER_ROOT, NETWORK } from '../../config';
import { AlertService } from '@full-fledged/alerts';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { environment } from '../../environments/environment';
import { getAccountInfo } from 'src/utils/getAccountInfo';
import {
  NgbModal,
  NgbActiveModal,
  ModalDismissReasons,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

declare interface coinInfo {
  img: string;
  name: string;
  token_address: string;
}
type defaultSwapValues = {
  tokenSwapInfo: string;
  tokenA: string;
  tokenB: string;
  amountIn: number;
  amountOut: number;
};
type swapResponse = {
  tx: string | null;
};

const defaultSwapResponse = {} as swapResponse;

@Component({
  selector: 'app-private-swap',
  templateUrl: './private-swap.component.html',
  styleUrls: ['./private-swap.component.scss'],
})
export class PrivateSwapComponent implements OnInit, OnDestroy {
  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly ready$ = this.walletservice.ready$;
  readonly connected$ = this.walletservice.connected$;
  readonly publicKey$ = this.walletservice.publicKey$;
  lamports = 0;
  swapResponseValues: swapResponse;
  recipient = '';

  title = 'Select Coin';
  img = '';
  coin2 = 'Select Coin';
  img2 = '';
  public isCollapsed = true;
  public isCollapsed2 = true;
  public menuItems: any[] = [];
  public menuItems2: any[] = [];
  public estimateOut = 0;
  public tokenABalance = this.walletservice.tokenABalance;
  public tokenBBalance = this.walletservice.tokenBBalance;
  setEstimateOut = 0;
  balance = 0;
  public defaultSwapValues: defaultSwapValues = {
    tokenSwapInfo: '',
    tokenA: '',
    tokenB: '',
    amountIn: null,
    amountOut: null,
  };
  public swapDetails = {
    swapId: '',
    tokenA: '',
    tokenB: '',
  };
  connection = this.walletservice.connection;
  public wallet = this.walletservice.wallet;

  // console.log(this.wallet);
  provider: any;
  // wallet: any;
  alert: string = null;
  tnxlink: string;
  refresh: NodeJS.Timeout;
  menuItems3: any;
  spinOn = false;
  noswap = false;
  coin_symbol: any;
  garyPrice: any = null;
  rlyPrice: any;
  timeOut;
  timeOutDuration = 1000;
  coinprice: any = null;
  coinprice2: any = null;
  coinprice2s: number;
  coinprices: number;
  refresh2: NodeJS.Timeout;
  neededTokens: any[];
  serror: boolean = false;
  exchange_message;
  refresh3: NodeJS.Timeout;
  canSwap: boolean = true;
  canswapMessage;
  coinfromroute: string = '';
  newMenuItems: any[];
  searchUser: boolean;
  order = 'coin_name';
  reverse = false;
  name2!: string;
  name3!: string;
  newMenuItems2: any[];
  showPage = false;
  menuItemsa: any[];

  //  = new AnchorProvider(this.connection, this.wallet, {});
  // provider: Provider = new Provider(this.connection, this.wallet, {});
  constructor(
    // private readonly _hdConnectionStore: ConnectionStore,
    // private readonly _hdWalletStore: WalletStore,
    private app: AppServiceService,
    private alertService: AlertService,
    private loadingBar: LoadingBarService,
    public walletservice: WalletService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private route: ActivatedRoute
  ) {
    this.coinfromroute = this.route.snapshot.paramMap.get('id');
    if (this.coinfromroute === null) {
      this.coinfromroute = '';
    }
    // console.log(this.coinfromroute);

    config.backdrop = 'static';
    config.keyboard = false;
    if (
      this.defaultSwapValues.amountIn == null ||
      this.defaultSwapValues.amountIn == 0
    ) {
      this.defaultSwapValues.amountOut == null;
    }

    // console.log(this.walletservice.wallet);
    this.app.getAllPrivateCoins().subscribe({
      next: (res: any) => {
        this.menuItems2 = [];
        this.menuItems = [];
        this.menuItemsa = [];
        console.log(res.data);
        this.menuItems3 = res.data;
        for (let i = 0; i < this.menuItems3.length; i++) {
          const element = this.menuItems3[i];
          if (element.coin_symbol == '$GARY') {
            this.menuItemsa.push(element);
          } else if (this.coinfromroute.length < 1) {
            this.menuItemsa.push(element);
          }
          if (this.coinfromroute.length > 0 && element.coin_symbol == 'sRLY') {
            this.menuItemsa.push(element);
          }
          if (
            this.coinfromroute.length > 0 &&
            element.coin_symbol === this.coinfromroute
          ) {
            this.menuItemsa.push(element);
          }
        }

        for (let a = 0; a < this.menuItemsa.length; a++) {
          const element = this.menuItemsa[a];
          if (element.coin_symbol !== 'sRLY') {
            this.menuItems.push(element);
          }

        }
        this.newMenuItems = this.menuItems;
        this.menuItems3 = this.menuItems;
        this.menuItems2 = this.menuItems;
        this.newMenuItems2 = this.menuItems2;
        // console.log(this.menuItems2);
      },
    });
  }

  // update_fields(data) {
  //   clearTimeout(this.timeOut);
  //   this.timeOut = setTimeout(() => {
  //     //do something
  //   }, this.timeOutDuration);
  // }
  estimate1(event) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.defaultSwapValues.amountIn = event;
      if (
        this.walletservice.wallet.publicKey &&
        this.defaultSwapValues.amountIn > 0
      ) {
        this.estimate();
      }
    }, this.timeOutDuration);
  }
  estimate2(event) {
    this.defaultSwapValues.amountOut = event;
    if (
      this.walletservice.wallet.publicKey &&
      this.defaultSwapValues.amountOut > 0
    ) {
      this.estimate();
    }
    // console.log(event);
  }


  generateSwapValues = async () => {
    // console.log();
    if (
      this.walletservice.exchange_status == 'offline' ||
      this.canSwap == false
    ) {
      if (this.walletservice.exchange_status == 'offline') {
        this.alertService.warning(this.walletservice.exchange_offline_message);
      } else if (this.canSwap == false) {
        this.alertService.warning(this.canswapMessage);
      }

      return {
        tokenSwap: null,
        tokenSwapInfoPubKey: null,
        amountInBN: null,
        amountOutBN: null,
        callerTokenAAccount: null,
        callerTokenBAccount: null,
        poolToken: null,
        feeAccount: null,
        tokenAccountA: null,
        tokenAccountB: null,
        tokenADecimals: null,
        tokenBDecimals: null,
        status: 'err',
      };
    }
    const { tokenA, tokenB, amountIn } = this.defaultSwapValues;
    // console.log("TokenA: "+ tokenA);
    // console.log("TokenB: "+ tokenB);
    const tokenSwapInfo = this.swapDetails.swapId;
    let tokenSwapInfoPubKey;
    let tokenAPubKey;
    let tokenBPubKey;
    const amountOut = 0;
    try {
      tokenSwapInfoPubKey = new PublicKey(tokenSwapInfo);
      tokenAPubKey = new PublicKey(tokenA);
      tokenBPubKey = new PublicKey(tokenB);
    } catch (error) {
      this.alertService.warning(error);
      this.spinOn = false;
    }

    //convert amount to proper units
    const connection = this.walletservice.connection;
    const { decimals: tokenADecimals } = await getMintInfo({
      tokenMint: tokenAPubKey,
      connection,
    });
    const { decimals: tokenBDecimals } = await getMintInfo({
      tokenMint: tokenBPubKey,
      connection,
    });

    let amountInBN;
    let amountOutBN;

    try {
      // amountInBN = baseToDec(new BN(amountIn), new BN(tokenADecimals));
      // alert(amountIn * Math.pow(10, tokenADecimals))
      amountInBN = new BN(amountIn * Math.pow(10, tokenADecimals));

      // alert(amountInBN);
      amountOutBN = baseToDec(new BN(amountOut), new BN(tokenBDecimals));
    } catch (error) {
      // alert(amountIn);
      this.alertService.danger(error);
      this.spinOn = false;
    }

    const provider = this.walletservice.provider;
    const tokenSwap = await tokenSwapProgram(provider);
    // alert (1);
    var { feeAccount, tokenAccountA, tokenAccountB, poolToken } =
      await getTokenSwapInfo(
        provider.connection,
        tokenSwapInfoPubKey,
        tokenSwap.programId
      );

    if (tokenA == this.swapDetails.tokenA) {
    } else {
      const ta = tokenAccountA;
      const tb = tokenAccountB;
      tokenAccountA = tb;
      tokenAccountB = ta;
    }
    let callerTokenAAccount, callerTokenBAccount;
    let status;
    this.neededTokens = [];
    this.serror = false;

    try {
      const associatedTokenA = await getAssociatedTokenAddress(
        tokenAPubKey,
        this.walletservice.wallet.publicKey
      );

      const associatedTokenB = await getAssociatedTokenAddress(
        tokenBPubKey,
        this.walletservice.wallet.publicKey
      );

      //  this. serror = false;
      //  error = false
      try {
        callerTokenAAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenA,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
      } catch (error) {
        if (
          error.message === 'TokenAccountNotFoundError' ||
          error.message === 'TokenInvalidAccountOwnerError'
        ) {
          this.neededTokens.push({
            title: this.title,
            token_address: this.defaultSwapValues.tokenA,
          });
          this.serror = true;
        }
      }
      try {
        callerTokenBAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenB,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
      } catch (error) {
        if (
          error.message === 'TokenAccountNotFoundError' ||
          error.message === 'TokenInvalidAccountOwnerError'
        ) {
          this.neededTokens.push({
            title: this.coin2,
            token_address: this.defaultSwapValues.tokenB,
          });
          this.serror = true;
        }
      }

      if (this.serror == true) {
        throw 'error';
      }

      callerTokenAAccount = callerTokenAAccount.address;
      callerTokenBAccount = callerTokenBAccount.address;

      return {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        status,
      };
    } catch (error) {
      // alert(error);
      // console.log(this.neededTokens);
      const modalRef = this.modalService.open(NgbdModalContent);
      // this.modalService.open(NgbdModalContent).result;
      modalRef.componentInstance.neededTokens = this.neededTokens;
      modalRef.componentInstance.walletService = this.walletservice;
      modalRef.componentInstance.lo = this.loadingBar;
      status = 'err';
      return {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        status,
      };
    }
    // try {
    //   callerTokenAAccount = await getOrCreateAssociatedTokenAccount(
    //     this.walletservice.connection,
    //     this.walletservice.wallet.publicKey,
    //     tokenAPubKey,
    //     this.walletservice.wallet.publicKey,
    //     this.walletservice.wallet.signTransaction
    //   );

    //   callerTokenBAccount = await getOrCreateAssociatedTokenAccount(
    //     this.walletservice.connection,
    //     this.walletservice.wallet.publicKey,
    //     tokenBPubKey,
    //     this.walletservice.wallet.publicKey,
    //     this.walletservice.wallet.signTransaction
    //   );

    //   status = 'success';
    // } catch (error) {
    //   status = 'err';
    //   this.stopLoading();
    //   this.alertService.danger(error);
    //   // alert(error);
    // }

    // console.log("ss "+callerTokenBAccount);
  };
  estimateSwapValues = async () => {
    const {
      tokenSwap,
      tokenSwapInfoPubKey,
      amountInBN,
      amountOutBN,
      callerTokenAAccount,
      callerTokenBAccount,
      poolToken,
      feeAccount,
      tokenAccountA,
      tokenAccountB,
      tokenADecimals,
      tokenBDecimals,
      status,
    } = await this.generateSwapValues();
    if (status == 'err') {
      return {
        amountA: 0,
        amountB: 0,
      };
    }
    try {
      var tokenABalance = (
        await this.walletservice.connection.getTokenAccountBalance(
          callerTokenAAccount
        )
      ).value.amount;
    } catch (error) {
      tokenABalance = '0';
    }

    try {
      var tokenBBalance = (
        await this.walletservice.connection.getTokenAccountBalance(
          callerTokenBAccount
        )
      ).value.amount;
    } catch (error) {
      tokenBBalance = '0';
    }
    // console.log(amountInBN+ "    "+tokenABalance)
    if (amountInBN.gt(new BN(tokenABalance))) {
      this.stopLoading();
      this.alertService.danger('Insufficient Fund');
      return {
        amountA: 0,
        amountB: 0,
      };
    }
    try {
      const connection = this.walletservice.connection;
      const wallet = this.walletservice.wallet;
      const { amountTokenAPostSwap, amountTokenBPostSwap } = await estimateSwap(
        {
          tokenSwap,
          tokenSwapInfo: tokenSwapInfoPubKey,
          amountIn: amountInBN,
          amountOut: amountOutBN,
          userTransferAuthority: this.walletservice.wallet.publicKey,
          userSourceTokenAccount: callerTokenAAccount,
          userDestinationTokenAccount: callerTokenBAccount,
          swapSourceTokenAccount: tokenAccountA,
          swapDestinationTokenAccount: tokenAccountB,
          poolMintAccount: poolToken,
          poolFeeAccount: feeAccount,
          wallet,
          connection,
        }
      );
      // console.log(amountTokenAPostSwap.toString());
      // console.log(amountTokenBPostSwap.toString());

      return {
        amountA: decToBase(
          new BN(tokenABalance).sub(new BN(amountTokenAPostSwap)),
          // new BN(amountTokenAPostSwap.toString()),
          new BN(tokenADecimals)
        ),
        amountB: decToBase(
          new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)),
          // new BN(amountTokenBPostSwap.toString()),
          new BN(tokenBDecimals)
        ),
      };
    } catch (error) {
      // console.log(error);
      this.alertService.danger('invalid amounts');
      return {
        amountA: decToBase(amountInBN, new BN(tokenADecimals)),
        amountB: decToBase(amountOutBN, new BN(tokenBDecimals)),
      };
    }
  };

  getcoinprice(coin) {
    this.app.getcoinprice(coin).subscribe({
      next: (res: any) => {
        console.log(res);
        this.coinprice = parseFloat(res.data.price_usd).toFixed(4);
        this.coinprices = parseFloat(res.data.price_usd);
      },
    });
  }

  getcoinprice2(coin) {
    this.app.getcoinprice(coin).subscribe({
      next: (res: any) => {
        console.log(res);
        this.coinprice2 = parseFloat(res.data.price_usd).toFixed(4);
        this.coinprice2s = parseFloat(res.data.price_usd);
      },
    });
  }
  search2() {
    this.newMenuItems = this.menuItems;
    if (this.name2 === '') {
      this.newMenuItems = this.menuItems;
      this.searchUser = false;
    } else {
      this.newMenuItems = this.newMenuItems.filter((resp) =>
        String(resp.coin_name)
          .toLocaleLowerCase()
          .match(this.name2.toLocaleLowerCase())
      );
    }
  }

  search3() {
    this.newMenuItems2 = this.menuItems2;
    if (this.name3 === '') {
      this.newMenuItems2 = this.menuItems2;
      this.searchUser = false;
    } else {
      this.newMenuItems2 = this.newMenuItems2.filter((resp) =>
        String(resp.coin_name)
          .toLocaleLowerCase()
          .match(this.name3.toLocaleLowerCase())
      );
    }
  }

  async selectCoin(name: any, img: any, token_address) {
    this.isCollapsed = true;
    this.title = name;
    this.img = img;
    this.defaultSwapValues.tokenA = token_address;
    this.menuItems2 = [];
    this.getcoinprice(name);
    this.defaultSwapValues.amountIn = 0;
    this.defaultSwapValues.amountOut = 0;

    for (let i = 0; i < this.menuItems3.length; i++) {
      const element = this.menuItems3[i];
      if (
        token_address === '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk' ||
        token_address === 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq' ||
        token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh'
      ) {
        if (
          token_address === 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq' ||
          token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh'
        ) {
          if (
            element.coin_symbol !== name &&
            element.token_address ===
            '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk'
          ) {
            this.menuItems2.push(element);
          }
        } else {
          if (element.coin_symbol !== name) {
            this.menuItems2.push(element);
          }
        }
      } else {
        if (
          element.coin_symbol !== name &&
          element.token_address ===
          '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk'
        ) {
          this.menuItems2.push(element);
        }
      }
      this.newMenuItems2 = this.menuItems2;
      // if (token_address !== '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk') {
      //   if (
      //     element.token_address !==
      //     'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq'
      //   ) {
      //     if (element.coin_symbol !== name) {
      //       this.menuItems2.push(element);
      //     }
      //   }
      // } else if (
      //   token_address === '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk'
      // ) {
      //   if (element.coin_symbol !== name) {
      //     this.menuItems2.push(element);
      //   }
      // }

      // else {
      //   if (element.coin_symbol !== name) {
      //     this.menuItems2.push(element);
      //   }
      // }
    }
    // console.log(this.menuItems2);
    this.app
      .getSwap(this.defaultSwapValues.tokenA, this.defaultSwapValues.tokenB)
      .subscribe({
        next: (res: any) => {
          // console.log(res);
          if (res.data !== null) {
            this.swapDetails.swapId = res.data.swap_id;
            this.swapDetails.tokenA = res.data.tokena_address;
            this.swapDetails.tokenB = res.data.tokenb_address;
          }
        },
      });
    // console.log('title', this.title);
    const callerTokenAAccount = await getAssociatedTokenAddress(
      new PublicKey(token_address),
      this.walletservice.wallet.publicKey
    );
    // console.log('hey ' + callerTokenAAccount);
    try {
      this.walletservice.tokenABalance = (
        await this.walletservice.connection.getTokenAccountBalance(
          callerTokenAAccount
        )
      ).value.uiAmount;
      // console.log(this.tokenABalance);
    } catch (error) {
      this.walletservice.tokenABalance = 0;
    }

    // this.defaultSwapValues.tokenA = name;
    const data = {
      key: this.publicKey$,
      address: token_address,
    };
    // this.app.getBalance(data).subscribe({
    //   next: (res: any) => {
    //     console.log(res);
    //     this.balance = res.data;
    //   },
    // });
    // this.publicKey$, token_address
  }
  async estimate() {
    this.spinOn = true;
    const { amountA, amountB } = await this.estimateSwapValues();
    // console.log(amountB);
    this.defaultSwapValues.amountIn = Number(amountA);
    this.defaultSwapValues.amountOut = Number(amountB);
    this.spinOn = false;
  }

  async anothercoin(name: any, img: any, token_address) {
    this.getcoinprice2(name);
    this.isCollapsed2 = true;
    this.coin2 = name;
    this.img2 = img;
    this.defaultSwapValues.amountIn = 0;
    this.defaultSwapValues.amountOut = 0;
    this.defaultSwapValues.tokenB = token_address;
    this.canSwap = true;
    this.app.checkResc(token_address).subscribe({
      next: (res: any) => {
        console.log(res);
        res.data.forEach(async (element) => {
          let check = 0;
          try {
            const associatedTokenA = await getAssociatedTokenAddress(
              new PublicKey(element.required_address),
              this.walletservice.wallet.publicKey
            );
            check = (
              await this.walletservice.connection.getTokenAccountBalance(
                associatedTokenA
              )
            ).value.uiAmount;
            // alert(check);
          } catch (error) {
            // alert(error);/
            // this.alertService.danger(error)
            check = 0;
          }
          // console.log('check', check);

          if (check < parseFloat(element.amount)) {
            this.canSwap = false;
            this.canswapMessage = element.message;
          }
        });
      },
    });
    this.menuItems = [];
    for (let i = 0; i < this.menuItems3.length; i++) {
      const element = this.menuItems3[i];
      if (
        token_address === '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk' ||
        token_address === 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq' ||
        token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh'
      ) {
        if (
          token_address === 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq' ||
          token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh'
        ) {
          if (
            element.coin_symbol !== name &&
            element.token_address ===
            '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk'
          ) {
            this.menuItems.push(element);
          }
        } else {
          if (element.coin_symbol !== name) {
            this.menuItems.push(element);
          }
        }
      } else {
        if (
          element.coin_symbol !== name &&
          element.token_address ===
          '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk'
        ) {
          this.menuItems.push(element);
        }
      }
      this.newMenuItems = this.menuItems;
    }
    // for (let i = 0; i < this.menuItems3.length; i++) {
    //   const element = this.menuItems3[i];
    //   if (element.coin_symbol !== name2) {
    //     // console.log(element);
    //     this.menuItems.push(element);
    //   }
    // }

    this.app
      .getSwap(this.defaultSwapValues.tokenA, this.defaultSwapValues.tokenB)
      .subscribe({
        next: (res: any) => {
          // console.log(res);
          if (res.data !== null) {
            this.swapDetails.swapId = res.data.swap_id;
            this.swapDetails.tokenA = res.data.tokena_address;
            this.swapDetails.tokenB = res.data.tokenb_address;
          }
        },
      });
    // console.log('coin2', this.coin2);
    const callerTokenBAccount = await getAssociatedTokenAddress(
      new PublicKey(token_address),
      this.walletservice.wallet.publicKey
    );

    try {
      this.walletservice.tokenBBalance = (
        await this.walletservice.connection.getTokenAccountBalance(
          callerTokenBAccount
        )
      ).value.uiAmount;
    } catch (error) {
      this.walletservice.tokenBBalance = 0;
    }

    // this.defaultSwapValues.tokenB = name2;
  }

  async swap() {
    const a = this.title;
    const b = this.coin2;
    const c = this.img;
    const d = this.img2;
    // this.coinprice = null;

    const title1 = this.title;
    const img1 = this.img;
    const token_address1 = this.defaultSwapValues.tokenA;

    const title2 = this.coin2;
    const img2 = this.img2;
    const token_address2 = this.defaultSwapValues.tokenB;

    await this.selectCoin(title2, img2, token_address2);
    await this.anothercoin(title1, img1, token_address1);
    this.defaultSwapValues.amountIn = 0;
    this.defaultSwapValues.amountOut = 0;
    // this.title = b;
    // this.coin2 = a;
    // this.img = d;
    // this.img2 = c;
  }

  test() {
    const html = `<a href="https://google.com" target="_blank">Open</a>`;
    this.alertService.success({ html });
  }
  ngOnInit() {
    this.walletservice.resetBalance();
    this.walletservice.getBags();
    this.getpricewid();
    // this.exchange_message = this.walletservice.exchange_message;
    this.refresh3 = setInterval(() => {
      console.log('checking');
      // console.log(this.wallet)
      // if(this.walletservice.showPrivate == true){
      //   this.showPage = true;
      // }else{
      //   this.showPage = false;
      // }
      if (this.walletservice.exchange_message !== undefined) {
        if (this.walletservice.exchange_message.length > 1) {
          this.exchange_message = this.walletservice.exchange_message;
        }
        clearInterval(this.refresh3);
      }
    }, 5000);

    this.refresh = setInterval(() => {
      console.log('running');
      this.getpricewid();
    }, 30000);
  }

  ngOnDestroy() {
    if (this.refresh) {
      clearInterval(this.refresh);
    }
  }

  async handleSubmit(e: any) {
    this.startLoading();
    this.noswap = true;
    e.preventDefault();
    if (this.walletservice.bagBalance < 500) {
      this.alertService.danger('You have less than 500 Bags');
      this.stopLoading();
      this.noswap = false;
      return;
    }
    if (!this.walletservice.wallet.publicKey) {
      this.alertService.danger('wallet not active');
      this.stopLoading();
      this.noswap = false;
    } else {
      const {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
      } = await this.generateSwapValues();
      const wallet = this.walletservice.wallet;
      const connection = this.walletservice.connection;
      const result = await executeSwap({
        tokenSwap,
        tokenSwapInfo: tokenSwapInfoPubKey,
        amountIn: amountInBN,
        amountOut: amountOutBN,
        userTransferAuthority: this.walletservice.wallet.publicKey,
        userSourceTokenAccount: callerTokenAAccount,
        userDestinationTokenAccount: callerTokenBAccount,
        swapSourceTokenAccount: tokenAccountA,
        swapDestinationTokenAccount: tokenAccountB,
        poolMintAccount: poolToken,
        poolFeeAccount: feeAccount,
        wallet,
        connection,
      });

      this.refresh2 = setInterval(async () => {
        let a = (
          await this.walletservice.connection.getTokenAccountBalance(
            callerTokenAAccount
          )
        ).value.uiAmount;

        let b = (
          await this.walletservice.connection.getTokenAccountBalance(
            callerTokenBAccount
          )
        ).value.uiAmount;

        if (a !== this.walletservice.tokenABalance) {
          this.walletservice.tokenABalance = a;
          this.walletservice.tokenBBalance = b;
          clearInterval(this.refresh2);
          this.selectCoin(this.title, this.img, this.defaultSwapValues.tokenA);
          this.anothercoin(
            this.coin2,
            this.img2,
            this.defaultSwapValues.tokenB
          );
        }
      }, 5000);

      this.swapResponseValues = { tx: result };
      this.tnxlink = `${EXPLORER_ROOT}/tx/${this.swapResponseValues.tx}?cluster=${environment.network}`;
      this.alert = `Swap successfully executed! `;

      const html = `
      <h4>${this.alert}</h4>
     <a href=${this.tnxlink} target="_blank">view transaction</a>`;
      this.alertService.success({ html });
      this.defaultSwapValues.amountIn = 0;
      this.defaultSwapValues.amountOut = 0;
      // console.log(callerTokenAAccount);
      this.stopLoading();
      this.noswap = false;
    }
  }
  // async test() {

  //   const callerTokenAAccount = await getOrCreateAssociatedTokenAccount(
  //     this.connection,
  //     this.wallet.publicKey,
  //     new PublicKey(this.defaultSwapValues.tokenA),
  //     this.wallet.publicKey,
  //     this.wallet.signTransaction
  //   );
  //   console.log(callerTokenAAccount);
  // }

  setMax() {
    this.defaultSwapValues.amountIn = Math.floor(
      this.walletservice.tokenABalance
    );
    if (
      this.walletservice.wallet.publicKey &&
      this.defaultSwapValues.amountIn > 0
    ) {
      this.estimate();
    }
    // this.estimate();
  }
  setMin() {
    this.defaultSwapValues.amountIn = null;
    this.defaultSwapValues.amountOut = null;
  }

  startLoading() {
    this.loadingBar.start();
  }

  stopLoading() {
    this.loadingBar.complete();
  }

  getpricewid() {
    this.app.getGaryPrice().subscribe({
      next: (res: any) => {
        console.log(res);
        this.coin_symbol = res.data.coin.coin_symbol;
        this.garyPrice = res.data.price_usd.toFixed(4);
        this.rlyPrice = res.data.price.toFixed(4);
      },
    });
  }
}

// @Component({
//   selector: 'ngbd-modal-content',
//   template: `
//     <div class="modal-header">
//       <h4 class="modal-title">Associated Token Account Required!</h4>
//       <button
//         type="button"
//         class="btn-close"
//         aria-label="Close"
//         (click)="activeModal.dismiss('Cross click')"
//       ></button>
//     </div>
//     <div class="modal-body">
//       <p>
//         You need to create an associated token account for the following tokens
//         to enable you swap.
//       </p>
//       <ul>
//         <li *ngFor="let item of neededTokens">
//           <p>{{ item.title }} - {{ item.token_address }}</p>
//         </li>
//       </ul>
//     </div>
//     <div class="modal-footer">
//       <button
//         type="button"
//         class="btn btn-outline-dark"
//         (click)="activeModal.close('Close click')"
//       >
//         Close
//       </button>
//       <button class="btn btn-primary" (click)="onClick()">Accept</button>
//     </div>
//   `,
// })
// export class NgbdModalContent {
//   // @Input() name;
//   @Input() neededTokens: any;
//   @Input() walletService: any;

//   constructor(public activeModal: NgbActiveModal, private alert: AlertService) {
//     // console.log(this.neededTokens);
//   }

//   onClick() {
//     this.neededTokens.forEach(async (element) => {
//       try {
//         await getOrCreateAssociatedTokenAccount(
//           this.walletService.connection,
//           this.walletService.wallet.publicKey,
//           new PublicKey(element.token_address),
//           this.walletService.wallet.publicKey,
//           this.walletService.wallet.signTransaction
//         );
//       } catch (error) {
//         this.alert.danger(error);
//         return;
//       }
//     });
//     this.activeModal.dismiss();
//   }
// }

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Associated Token Account Required!</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <p>
        You need to create an associated token account for the following tokens
        to enable you swap.
      </p>
      <ul>
        <li *ngFor="let item of neededTokens">
          <p>{{ item.title }} - {{ item.token_address }}</p>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="onClose()">
        Close
      </button>
      <button class="btn btn-primary" (click)="onClick()">
        Accept<span class="load" *ngIf="spinOn"
          ><i class="fa-solid fa-spinner fa-spin"></i
        ></span>
      </button>
    </div>
  `,
})
export class NgbdModalContent {
  // @Input() name;
  @Input() neededTokens: any;
  @Input() walletService: any;
  @Input() lo: any;
  spinOn: boolean;
  error: boolean;

  constructor(public activeModal: NgbActiveModal, private alert: AlertService) {
    // console.log(this.neededTokens);
    this.spinOn = false;
  }

  async onClick() {
    this.spinOn = true;
    try {
      this.spinOn = true;
      await getOrCreateAssociatedTokenAccount(
        this.walletService.connection,
        this.walletService.wallet.publicKey,
        new PublicKey(this.neededTokens[0].token_address),
        this.walletService.wallet.publicKey,
        this.walletService.wallet.signTransaction
      );
      this.alert.success('Associated Account created successfully');
    } catch (error) {
      this.spinOn = false;
      this.lo.complete();
      this.alert.danger(error);
      return;
    }
    if (this.neededTokens.length > 1) {
      try {
        this.spinOn = true;
        await getOrCreateAssociatedTokenAccount(
          this.walletService.connection,
          this.walletService.wallet.publicKey,
          new PublicKey(this.neededTokens[1].token_address),
          this.walletService.wallet.publicKey,
          this.walletService.wallet.signTransaction
        );
        this.alert.success('Associated Account created successfully');
        this.spinOn = false;
        this.lo.complete();
        this.activeModal.dismiss();
      } catch (error) {
        this.spinOn = false;
        this.lo.complete();
        this.alert.danger(error);
        return;
      }
    } else {
      this.spinOn = false;
      this.lo.complete();
      this.activeModal.dismiss();
    }
    // this.spinOn = false;
    // this.lo.complete();

    // await this.neededTokens.forEach(async (element) => {
    //   try {
    //     this.spinOn = true;
    //     await getOrCreateAssociatedTokenAccount(
    //       this.walletService.connection,
    //       this.walletService.wallet.publicKey,
    //       new PublicKey(element.token_address),
    //       this.walletService.wallet.publicKey,
    //       this.walletService.wallet.signTransaction
    //     );

    //     alert ("Associated Account created successfully");
    //     this.spinOn = false;
    //     this.lo.complete();
    //     this.error = false;
    //   } catch (error) {
    //     this.error = true;
    //     this.spinOn = false;
    //     this.lo.complete();
    //     this.alert.danger(error);
    //     return;
    //   }
    //   // i++;
    //   // if(this.neededTokens.length > i){
    //   //   this.lo.complete();
    //   // this.spinOn = false;
    //   // this.activeModal.dismiss();
    //   // if(this.error == false){
    //   //   alert ("Associated Account created successfully");
    //   //   return
    //   // }
    //   // }
    // });
  }

  onClose() {
    this.lo.complete();
    this.activeModal.close('Close click');
  }
}
