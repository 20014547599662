import { AlertService } from '@full-fledged/alerts';
import { AppServiceService } from './../../../src/app/_services/app-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import moment from 'moment';
moment().format();
@Component({
  selector: 'app-gary-details',
  templateUrl: './gary-details.component.html',
  styleUrls: ['./gary-details.component.scss'],
})
export class GaryDetailsComponent implements OnInit, OnDestroy {
  model = 'today';
  id: string;
  data: any;
  page: number;
  size: any;
  perpage: any;
  loading: boolean = true;
  price: any;
  img: any = 'assets/img/dollar.png';
  garylocked: any;
  coinlocked: number;
  coin_symbol: any;
  garyPrice: any;
  tvl: number;
  loading2: boolean = true;
  today: string;
  yesterday: string;
  chartprice: any[] = [];
  dateentered: any[] = [];
  tomorrow: string;
  loading3: boolean = true;
  refresh: NodeJS.Timeout;
  gary_locked: any[] = [];
  usd_price: any[] = [];
  volume_traded: any[] = [];
  key = 'created_at';
  direction = 'desc';

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: this.chartprice,
        // label: 'Series A',
        backgroundColor: '#610a7c7d',
        borderColor: '#610a7c',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      },
    ],
    labels: this.dateentered,
  };

  public lineChartData2: ChartConfiguration['data'] = {
    datasets: [
      {
        data: this.gary_locked,
        // label: 'Series A',
        backgroundColor: '#610a7c7d',
        borderColor: '#610a7c',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      },
    ],
    labels: this.dateentered,
  };

  public lineChartData3: ChartConfiguration['data'] = {
    datasets: [
      {
        data: this.usd_price,
        // label: 'Series A',
        backgroundColor: '#610a7c7d',
        borderColor: '#610a7c',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      },
    ],
    labels: this.dateentered,
  };
  public lineChartData4: ChartConfiguration['data'] = {
    datasets: [
      {
        data: this.volume_traded,
        // label: 'Series A',
        backgroundColor: '#610a7c7d',
        borderColor: '#610a7c',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      },
    ],
    labels: this.dateentered,
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.8,
      },
    },
    scales: {
      x: { ticks: { color: '#fff' }, grid: { color: 'rgba(255,255,255,0)' } },
      y: {
        ticks: { color: '#fff' },
        grid: { color: 'rgba(255,255,255,0.1)' },
      },
    },

    plugins: {
      legend: { display: false },
    },
  };

  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  volume: number;
  price_usd: any;
  swapdata = true;
  type: string;
  token_address: any;

  constructor(
    private route: ActivatedRoute,
    private app: AppServiceService,
    private router: Router,
    private alert: AlertService
  ) {
    this.id = '$GARY';
    this.id = this.id.toUpperCase();
  }

  ngOnInit(): void {
    this.page = 1;
    this.today = moment().format('YYYY-MM-DD HH:MM');
    this.type = 'hour';
    this.yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD HH:MM');
    // this.today = this.yesterday;
    this.tomorrow = moment().add(1, 'days').format('YYYY-MM-DD HH:MM');
    this.app.getAllCoins().subscribe({
      next: (res: any) => {
        this.token_address = {};
        console.log(res.data);
        let d = res.data;
        for (let i = 0; i < d.length; i++) {
          this.token_address[d[i].token_address] = d[i];
        }

        // console.log(this.token_address);
      },
      complete: () => {
        this.getAllTrades();
        this.getCoinDetails();
        // this.getpricewid();
        this.getPriceCharts();
      },
    });

    // this.refresh = setInterval(() => {
    //   this.getAllTrades();
    //   this.getCoinDetails();

    //   this.getPriceCharts();
    // }, 300000);
    console.log(this.tomorrow);
  }

  ngOnDestroy() {
    // if (this.refresh) {
    //   clearInterval(this.refresh);
    // }
  }

  gotoTrage() {
    this.router.navigateByUrl(`/swap/${this.id}`);
  }
  getAllTrades() {
    this.loading = true;
    this.app.getTrades(this.id, this.page, this.key, this.direction).subscribe({
      next: (res: any) => {
        // console.log(res);
        // let allData = res.data;
        let ta = this.token_address;
        // console.log(res.data);
        ta['RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq'] = {
          coin_symbol: 'sRLY',
        };
        for (let index = 0; index < res.data.data.length; index++) {
          // const element = array[index];
          let d = JSON.parse(res.data.data[index].data);
          // console.log(d);
          let m = d.meta.preTokenBalances[0].mint;
          if (
            m == '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk' ||
            ta[m] == undefined
          ) {
            m = d.meta.preTokenBalances[1].mint;
            if (
              m == '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk' ||
              ta[m] == undefined
            ) {
              m = d.meta.preTokenBalances[2].mint;
            }
            if (
              m == '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk' ||
              ta[m] == undefined
            ) {
              m = d.meta.preTokenBalances[3].mint;
            }
            if (
              m == '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk' ||
              ta[m] == undefined
            ) {
              m = d.meta.preTokenBalances[4].mint;
            }
            console.log(d.meta.preTokenBalances);
          }
          if (ta[m] == undefined) {
            // console.log(d.meta.preTokenBalances);
            // res.data.data[index].coin_symbol = "sRLY";
            m = d.meta.preTokenBalances[0].mint;
            res.data.data[index].coin_symbol = ta[m].coin_symbol;
          } else {
            res.data.data[index].coin_symbol = ta[m].coin_symbol;
          }
        }

        let allData = res.data;
        // console.log(allData);
        this.data = allData.data;
        this.size = allData.total;
        this.perpage = allData.per_page;
        // this.allrooms = data.rooms.data;
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
      },
    });
  }

  truncateString(str) {
    if (str.length <= 10) {
      return str;
    }
    return str.slice(0, 10) + '...';
  }

  formatCoin(str) {
    // console.log(str['meta'].innerInstructions['instructions']);
    if (this.token_address[str] !== undefined) {
      // return  this.token_address[str].coin_symbol;
    } else {
      // return str;
    }
    // if (str.length <= 10) {
    //   return str;
    // }
    // return str.slice(0, 10) + '...';
  }

  formatDate(date) {
    // let datee = date;
    // datee = moment().format('YYYY-MM-DD HH:MM:SS');
    // return datee;
    return moment.unix(date).format();
  }

  getPage(page: any) {
    this.page = page;
    this.getAllTrades();
    // this.getRoomHistory();
  }

  async getCoinDetails() {
    this.loading2 = true;
    this.app.getcoinprice(this.id).subscribe({
      next: async (res: any) => {
        const alldata = res.data;
        this.price = alldata.coin.coin_info.price_gary;
        this.price_usd = alldata.price_usd;
        this.img = alldata.coin.coin_img;
        this.garylocked = alldata.coin.coin_info.gary_locked;
        const coinswapped = parseFloat(alldata.coin.coin_info.coin_swapped);
        const a = coinswapped * 1000000000;
        this.coinlocked = parseFloat(alldata.coin.mint) - a;
        let srly_price = this.price_usd / this.price;
        this.tvl = this.garylocked * this.price_usd;
      },

      complete: async () => {
        await this.getpricewid();
        await this.getVolume();
        this.loading2 = false;
      },
      error: (err: any) => {
        this.loading2 = false;
        console.log(err);
      },
    });
  }

  async getpricewid() {
    // this.loading2 = true;
    this.coin_symbol = 'sRLY';
    // this.app.getGaryPrice().subscribe({
    //   next: (res: any) => {
    //     // console.log(res);
    //     // this.coin_symbol = "sRLY";
    //     // this.garyPrice = res.data.price_usd;
    //     // this.tvl = this.garylocked * this.garyPrice;
    //     // this.loading2 = false;
    //   },
    // });
  }

  async getVolume() {
    const today = moment().format('YYYY-MM-DD HH:MM');
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD HH:MM');
    this.app.getVolumeTraded(this.id, yesterday, today).subscribe({
      next: (res: any) => {
        const alldata = parseFloat(res.data);
        this.volume = parseFloat(this.price_usd) * alldata;
      },
      error: (err: any) => {
        this.alert.danger(err.error.message);
      },
    });
  }

  getPriceCharts() {
    this.loading3 = true;
    this.dateentered.length = 0;
    this.chartprice.length = 0;
    this.gary_locked.length = 0;
    this.usd_price.length = 0;
    this.volume_traded.length = 0;
    this.app.getPriceCharts(this.id, this.yesterday, this.today).subscribe({
      next: (res: any) => {
        console.log(res);
        let alldata;
        let type;
        if (Array.isArray(res.data)) {
          alldata = res.data;
          // alldata.reverse()
          type = 'hour';
        } else {
          alldata = Object.values(res.data);

          // alldata.reverse()
          type = 'day';
          //  console.log(alldata)
          //  alldata = alldata[1];
        }

        alldata.forEach((element: string | any[]) => {
          // console.log(element);
          // const length = element.length;
          let test, f;

          if (element[0] !== undefined) {
            // console.log(element.length);
            f = element[element.length - 1];
            // console.log(alldata.length - 1);
            // console.log(element[alldata.length - 1]);
            // console.log(element[alldata.length - 2]);
            test = element[element.length - 1].date_entered;
          } else {
            f = element;
            test = element['date_entered'];
          }

          const test2 = test.split(' ');
          let date;
          if (this.type == 'hour') {
            date = test2[1];
          } else {
            date = test2[0];
          }

          this.dateentered.push(date);

          const price = parseFloat(f.price_gary).toFixed(5);
          this.chartprice.push(price);

          const priceusd = parseFloat(f.price_usd).toFixed(5);
          this.usd_price.push(priceusd);

          const volume = parseFloat(f.volume_traded).toFixed(5);
          this.volume_traded.push(volume);

          const glock = parseFloat(f.gary_locked).toFixed(5);
          this.gary_locked.push(glock);
        });

        this.loading3 = false;
        // console.log(this.dateentered);
      },
      error: (err: any) => {
        console.log(err);
        this.alert.danger(err.error.message);
      },
    });
  }

  changeDate(ev: any) {
    console.log(ev);
    if (ev == 'today') {
      this.type = 'hour';
      this.today = moment().format('YYYY-MM-DD HH:MM');
      this.yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD HH:MM');
      // this.today = this.yesterday;
    } else {
      this.type = 'day';
      const day: number = parseInt(ev);
      this.yesterday = moment()
        .subtract(day, 'days')
        .format('YYYY-MM-DD HH:MM');
      this.today = moment().format('YYYY-MM-DD HH:MM');
    }
    this.getPriceCharts();
  }

  swap() {
    this.swapdata = !this.swapdata;
  }
}
