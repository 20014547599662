<ff-alerts></ff-alerts>
<ngx-loading-bar color="#EBC6F7"></ngx-loading-bar>

<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand" href="#">
      <img src="assets/img/garylight.png" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">


      </ul>
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="['/swap']" routerLinkActive="active">Swap</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="['/wormhole']" routerLinkActive="active">Wormhole</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://discord.gg/garyclub" target="_blank">Discord</a>
        </li>


      </ul>

      <div ngbDropdown class="d-inline-block" *ngIf="(connected$ | async) === false">
        <button type="button" class="btn btn-outline-success py-3 px-5" id="dropdownBasic1" ngbDropdownToggle><i
            class="fa-solid fa-wallet"></i> &nbsp;&nbsp; Connect
          Wallet</button>
        <ul ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <li *ngFor="let wallet of wallets$ | async">
            <button ngbDropdownItem (click)="onSelectWallet(wallet.adapter.name)"> {{ wallet.adapter.name }}
              ({{ wallet.readyState }})</button>
          </li>
        </ul>
      </div>

      <div ngbDropdown class="d-inline-block" *ngIf="connected$ | async">
        <button type="button" class="btn btn-outline-success py-3  px-5" id="dropdownBasic2" ngbDropdownToggle><i
            class="fa-solid fa-wallet"></i>
          {{truncate( publicKey$ | async) }}
          {{ walletName$ | async }}</button>
        <ul ngbDropdownMenu aria-labelledby="dropdownBasic2">
          <li>
            <button ngbDropdownItem (click)="onDisconnect()" *ngIf="connected$ | async">
              Disconnect
            </button></li>

        </ul>
      </div>
    </div>
  </div>
</nav> -->

<main>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid mx-md-5">
        <a class="navbar-brand" href="#">
          <img src="assets/img/garylight.png" alt="" />
        </a>
        <button class="navbar-toggler text-white" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
          <!-- <span class="navbar-toggler-icon text-white"></span> -->
          <i class="fa-solid fa-bars text-white"></i>
        </button>

        <!-- Step 2: Add the ngbCollapse directive to the element below. -->
        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 text-center">
            <li class="nav-item">
              <!-- Step 4: Close the menu when a link is clicked. -->
              <a class="nav-link" [routerLink]="['/swap']" routerLinkActive="active"
                (click)="isMenuCollapsed = true">Swap</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/wormhole']" routerLinkActive="active"
                (click)="isMenuCollapsed = true">Wormhole</a>
            </li>
            <!-- <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/sol']"
                routerLinkActive="active"
                (click)="isMenuCollapsed = true"
                >sRLY Swap</a
              >
            </li> -->
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/gary']" routerLinkActive="active"
                (click)="isMenuCollapsed = true">Buy $GARY</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/private_swap']" routerLinkActive="active"
                (click)="isMenuCollapsed = true">Private Swap</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/coin_lists']" routerLinkActive="active"
                (click)="isMenuCollapsed = true">Coin List</a>
            </li>

            <!--        <li class="nav-item">-->
            <!--          <a class="nav-link" href="https://discord.gg/garyclub" target="_blank">Discord</a>-->
            <!--        </li>-->
            <!-- <li class="nav-item">
          <a class="nav-link" target="_blank"
            href="https://2522188.hs-sites.com/en/knowledge/guide-on-how-to-buy-gary-coin">How to buy
            $GARY</a>
        </li> -->
            <!--        <li class="nav-item">-->
            <!--          <a class="nav-link" target="_blank"-->
            <!--            href="https://docs.socialconnector.io/social-connector/exchange-faq">FAQ</a>-->
            <!--        </li>-->
          </ul>

          <!--      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">-->
          <!--        <li class="nav-item" *ngIf="garyPrice">-->
          <!--          {{coin_symbol}}: ${{ garyPrice }}-->
          <!--        </li>-->
          <!--      </ul>-->
          <div class="text-center">
            <div ngbDropdown class="d-inline-block" *ngIf="(connected$ | async) === false">
              <button type="button" class="btn btn-outline-success py-3 px-5" id="dropdownBasic1" ngbDropdownToggle>
                <i class="fa-solid fa-wallet"></i> &nbsp;&nbsp; Connect Wallet
              </button>
              <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu3 dropdown-menu-dark">
                <li *ngFor="let wallet of wallets$ | async">
                  <button ngbDropdownItem class="dropdown-item2 d-flex align-items-center"
                    (click)="onSelectWallet(wallet.adapter.name)">
                    <span>
                      <img [src]="fixUnsafe(wallet.adapter.icon)" width="20" height="20" class="me-3" alt="" /></span>{{
                    wallet.adapter.name }}
                  </button>
                </li>
              </ul>
            </div>

            <div ngbDropdown class="d-inline-block" *ngIf="connected$ | async">
              <button type="button" class="btn btn-outline-success py-2 px-5 text-end" id="dropdownBasic2"
                ngbDropdownToggle>
                <span>
                  <img [src]="fixUnsafe(walletIcon$ | async)" width="20" height="20" class="me-3" alt="" /></span>
                {{ truncate(publicKey$ | async) }} <br />
                <span class="text-small text-end">{{
                  walletName$ | async
                  }}</span>
              </button>
              <ul ngbDropdownMenu aria-labelledby="dropdownBasic2" class="dropdown-menu3 dropdown-menu-dark">
                <li>
                  <button ngbDropdownItem class="dropdown-item2 d-flex align-items-center" (click)="copyText()"
                    *ngIf="connected$ | async">
                    <i class="fa-solid fa-copy"></i> &nbsp;&nbsp; Copy Address
                  </button>
                </li>
                <li>
                  <button ngbDropdownItem class="dropdown-item2 d-flex align-items-center" (click)="onDisconnect()"
                    *ngIf="connected$ | async">
                    <i class="fa-solid fa-circle-xmark"></i>&nbsp;&nbsp;
                    Disconnect
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <router-outlet></router-outlet>
</main>

<footer class="footer">
  <div class="row my-4 text-center">
    <ul class="list-unstyled my-list">
      <li>
        <a href="https://discord.gg/garyclub" target="_blank">DISCORD</a>
      </li>
      <li>
        <a href="https://docs.socialconnector.io" target="_blank" style="font-weight: bold">DOCS</a>
      </li>
      <li>
        <a target="_blank" href="https://docs.socialconnector.io/social-connector/exchange-faq">FAQ</a>
      </li>
    </ul>
  </div>
</footer>
