import {JupiterComponent, NgbdModalContentJupiter} from './jupiter/jupiter.component';
import { GaryswapComponent } from './gary/solswap/garyswap.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HdWalletAdapterModule } from '@heavy-duty/wallet-adapter';
import { HttpClientModule } from '@angular/common/http';
// Import BrowserAnimationsModule
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Import your library
import { AlertModule } from '@full-fledged/alerts';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgChartsModule } from 'ng2-charts';
import {
  WormholeComponent,
  NgbdModalContent1,
} from './wormhole/wormhole.component';
import { NgbdModalContent, SwapComponent } from './swap/swap.component';
import { CommonModule } from '@angular/common';
import { CoinlistComponent } from './coinlist/coinlist.component';
import { OrderModule } from 'ngx-order-pipe';
import {
  NgbdModalContent2,
  SolswapComponent,
} from './sol/solswap/solswap.component';
import { SwapDetailsComponent } from './swap-details/swap-details.component';
import { DateAsAgoPipe } from './shared/date-as-ago.pipe';
import { GaryDetailsComponent } from './gary-details/gary-details.component';
import { PrivateSwapComponent } from './private-swap/private-swap.component';
import { CreatorSwapComponent } from './creator-swap/creator-swap.component';
import { importExpr } from '@angular/compiler/src/output/output_ast';

@NgModule({
  declarations: [
    AppComponent,
    WormholeComponent,
    SwapComponent,
    NgbdModalContent,
    NgbdModalContent1,
    NgbdModalContent2,
    NgbdModalContentJupiter,
    CoinlistComponent,
    SolswapComponent,
    GaryswapComponent,
    SwapDetailsComponent,
    DateAsAgoPipe,
    GaryDetailsComponent,
    PrivateSwapComponent,
    CreatorSwapComponent,
    JupiterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    CommonModule,
    AlertModule.forRoot({ maxMessages: 5, timeout: 50000, positionX: 'right' }),
    HdWalletAdapterModule.forRoot({ autoConnect: true }),
    OrderModule,
    NgChartsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [DateAsAgoPipe],
})
export class AppModule {}
