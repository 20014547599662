<div class="container" *ngIf="walletservice.showPrivate">
  <div class="row mt-5">
    <div class="col-md-6 offset-md-3">
      <!-- <ngb-alert [dismissible]="true" type="success" *ngIf="alert">
        {{ alert }} &nbsp;<a [href]="tnxlink" target="_blank">view transaction</a>
      </ngb-alert> -->
      <ngb-alert [dismissible]="false" type="info" *ngIf="exchange_message" class="text-center">
        {{ exchange_message }}
      </ngb-alert>
      <br>

      <!-- <button (click)="test()">Test</button> -->

      <div class="text-end me-2" *ngIf="garyPrice">
        <p style="color: #fff !important;">{{coin_symbol}}: ${{ garyPrice }} </p>
      </div>
      <div class="card dropcard mb-3">
        <div class="bg">
          <div class=" px-md-5 px-3 py-5">
            <div class="row">
              <div class="col-6">
                <p>Trade</p>
                <div ngbDropdown class="d-inline-block">
                  <button type="button" class="btn btn-clear mybtn text-white" id="dropdownBasic3" ngbDropdownToggle>
                    <span><img width="30" [src]="img" class="mb-1" style="border-radius: 50%"></span> {{ title}}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="dropdown-menu2">
                    <div style="position: relative;">
                      <div class="row pabs">
                        <div class="col-12">
                          <div class="input-group mb-3">
                            <span class="input-group-text">
                              <i class="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input type="search" class="form-control" placeholder="Filter by token name" name="name2"
                              [(ngModel)]="name2" (ngModelChange)="search2()">
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="dropcontent mt-2">
                      <div class="row ">
                        <ul class="multi-column-dropdown">
                          <li *ngFor="let item of newMenuItems | orderBy: order : reverse:reverse">
                            <a style="cursor: pointer;" ngbDropdownItem
                              (click)="selectCoin(item.coin_symbol, item?.coin_img, item.token_address)">
                              <img [src]="item?.coin_img" alt="" width="30" style="border-radius: 50%">
                              {{item?.coin_name}}
                            </a>
                          </li>
                        </ul>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-6">
                <p class="light mb-2 me-2">
                  <span class="float-start"> Balance : {{ walletservice.tokenABalance | number : '1.2-4'}} </span>
                  <span class="float-end" *ngIf="coinprice">
                    ${{walletservice.tokenABalance * coinprices | number : '1.2-4'}} </span>
                </p>
                <div style="clear: both;" class="mb-2"></div>
                <div class="input-group">
                  <button class="btn btn-sm btn-min" type="button" (click)="setMax()">Max</button>
                  <input type="text" class="form-control form-control2 text-end px-3" placeholder="0"
                    [(ngModel)]="defaultSwapValues.amountIn" (ngModelChange)="estimate1($event)">
                </div>

                <!-- <input type="text" class="form-control form-control2 text-end px-3" placeholder="0"
                  [(ngModel)]="defaultSwapValues.amountIn" (ngModelChange)="estimate1($event)">-->
                <div class=" mt-1 me-2">
                  <p *ngIf="coinprice">
                    <span class="float-start"> ${{ coinprice }} </span>
                    <span class="float-end"> ${{ defaultSwapValues.amountIn * coinprices | number : '1.2-4' }} </span>
                  </p>
                  <div style="clear: both;" class="mb-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="btn swapbtn" (click)="swap()">
          <img src="assets/img/switch.png" alt="">
        </span>
        <span class="load" *ngIf="spinOn"><i class="fa-solid fa-spinner fa-spin"></i></span>


        <div class="px-md-5 px-3 py-3">
          <div class="row">
            <div class="col-6">
              <p>For (estimated)</p>
              <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-clear mybtn text-white" id="dropdownBasic4" ngbDropdownToggle>
                  <span><img [src]="img2" class="mb-1" width="30" style="border-radius: 50%"></span> {{ coin2 }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic4" class="dropdown-menu2">
                  <div style="position: relative;">
                    <div class="row pabs">
                      <div class="col-12">
                        <div class="input-group mb-3">
                          <span class="input-group-text">
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                          <input type="search" class="form-control" placeholder="Filter by token name" name="name2"
                            [(ngModel)]="name3" (ngModelChange)="search3()">
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="dropcontent mt-2">
                    <div class="row ">
                      <br>
                      <div class="col-sm-12 ps-3">
                        <ul class="multi-column-dropdown">
                          <li *ngFor="let item of newMenuItems2 | orderBy: order : reverse:reverse">
                            <a ngbDropdownItem style=" cursor: pointer;"
                              (click)="anothercoin(item.coin_symbol, item.coin_img,item.token_address)">
                              <img [src]="item.coin_img" alt="" width="30" style="border-radius: 50%">
                              {{item.coin_name}}
                            </a>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-6">
              <p class="light mb-2 me-2">
                <span class="float-start"> Balance : {{ walletservice.tokenBBalance | number : '1.2-4' }} </span>
                <span class="float-end" *ngIf="coinprice2">
                  ${{walletservice.tokenBBalance * coinprice2s | number : '1.2-4'}} </span>
              </p>
              <div style="clear: both;" class="mb-2"></div>
              <!-- <p class="light mb-2">Balance {{ walletservice.tokenBBalance }}</p> -->
              <input type="text" class="form-control form-control2 text-end px-3" disabled placeholder="0"
                [(ngModel)]="defaultSwapValues.amountOut">
              <div class=" mt-1 me-2">
                <p *ngIf="coinprice2">
                  <span class="float-start"> ${{ coinprice2 }} </span>
                  <span class="float-end"> ${{ defaultSwapValues.amountOut * coinprice2s | number : '1.2-4'}} </span>
                </p>
                <div style="clear: both;" class="mb-2"></div>
              </div>

            </div>
          </div>
        </div>

        <div class="text-center mt-2">
          <p class="light mb-3"><i class="fa-solid fa-circle-info text-warning"></i> &nbsp; We recommend having at
            least <b>0.05 SOL</b>
            for any transaction</p>
          <!-- <button (click)="test()">Test</button> -->
          <button class="btn btn-connect"
            [disabled]="defaultSwapValues.amountOut <=0 || defaultSwapValues.amountOut ==null  || noswap"
            (click)="handleSubmit($event)">Swap</button>
        </div>

      </div>
    </div>
  </div>
</div>


<div class="container" *ngIf="!walletservice.showPrivate">
  <div class="row mt-5">
    <div class="col-md-6 offset-md-3">




      <br><br><br>
      <div class="card dropcard m-3">

        <div class="card-body p-5 text-center">
          <h3>You need to have at least 500 $BAGS to access this page</h3>
        </div>

      </div>
    </div>
  </div>
</div>
